import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Typography,
  Button,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  CircularProgress
} from "@mui/material";
import { styled } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import { ApiUrl } from "../Auth/basicConfig";
import Header from "../Components/Header";
// import BacktestResults from "../Components/BacktestResults";

// Styled Components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
  backgroundColor: "#f5f5f5",
  "& .MuiTypography-body2": {
    fontSize: "1.2rem",
    marginBottom: theme.spacing(1),
  },
  "& .MuiTypography-h6": {
    fontSize: "1.5rem",
    fontWeight: 600,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0.5),
  textTransform: "none",
  fontSize: "1rem",
  padding: theme.spacing(1, 2),
}));

const ActionButton = styled(Button)(({ theme }) => ({
  minWidth: "unset",
  padding: theme.spacing(0.5, 2),
  fontSize: "0.95rem",
}));

const GreenButton = styled(StyledButton)({
  backgroundColor: "#4caf50",
  color: "white",
  "&:hover": {
    backgroundColor: "#45a049",
  },
});

const LoadingContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  gap: theme.spacing(2),
  "& .MuiCircularProgress-root": {
    color: "#4caf50",
  },
}));

const BacktestProgress = styled(LinearProgress)({
  width: '100%',
  marginTop: 8,
  marginBottom: 8,
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#4caf50',
  },
});

const FormulaList = () => {
  const [formulas, setFormulas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [backtestStatus, setbacktestStatus] = useState({});
  const [selectedFormula, setSelectedFormula] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [matchData, setMatchData] = useState(null);
  const [backtestStatus, setBacktestStatus] = useState({});
  const navigate = useNavigate();

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };



  const handleBacktestStart = async (formulaId) => {
    try {
      console.log("Starting backtest for formula ID:", formulaId);
      const action = "backtest";
      // START BACKTEST
      await axios.post(`${ApiUrl.baseURL}/backtest?formula_id=${formulaId}&action=${action}`, {});

      // SETUP SSE CONNECTION
      const eventSource = new EventSource(`${ApiUrl.baseURL}/backtest?formula_id=${formulaId}&action=${action}`);

      setBacktestStatus((prevStatus) => ({
        ...prevStatus,
        [formulaId]: {
          isLoading: true,
          progress: 0,
          message: "",
          error: null,
          hasResults: false,
        },
      }));

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        setBacktestStatus((prevStatus) => ({
          ...prevStatus,
          [formulaId]: {
            ...prevStatus[formulaId],
            progress: data.progress,
            message: data.message,
          },
        }));

        if (data.progress === 100) {
          eventSource.close();
          setBacktestStatus((prevStatus) => ({
            ...prevStatus,
            [formulaId]: {
              ...prevStatus[formulaId],
              isLoading: false,
              hasResults: true,
            },
          }));
        }
      };

      eventSource.onerror = () => {
        setBacktestStatus((prevStatus) => ({
          ...prevStatus,
          [formulaId]: {
            ...prevStatus[formulaId],
            isLoading: false,
            error: "Error connecting to backtest service",
          },
        }));
        eventSource.close();
      };
    } catch (error) {
      console.error(`Error starting backtest:`, error);
      setBacktestStatus((prevStatus) => ({
        ...prevStatus,
        [formulaId]: {
          ...prevStatus[formulaId],
          isLoading: false,
          error: "Error starting backtest",
        },
      }));
    }
  };
  const handleReset = async (formulaId) => {
    try {
      console.log("Resetting backtest for formula ID:", formulaId);
      const action = "backtest";
      await axios.get(`${ApiUrl.baseURL}/reset_backtest?formula_id=${formulaId}&action=${action}`);
      console.log(`Successfully reset backtest for formula ID: ${formulaId}`);

      window.location.reload()
    } catch (error) {
      // Log error
      console.error(`Error resetting backtest for formula ID ${formulaId}:`, error);
    }
  };


  const handleBacktestResume = async (formulaId) => {
    try {
      // console.log("Starting backtest for formula ID:", formulaId);
      // START BACKTEST
      // await axios.post(`${ApiUrl.baseURL}/backtest?formula_id=${formulaId}`);
      console.log("Resuming backtest for formula ID:", formulaId);
      // SETUP SSE CONNECTION
      const action = "backtest"
      const eventSource = new EventSource(`${ApiUrl.baseURL}/backtest?formula_id=${formulaId}&action=${action}`);

      setBacktestStatus((prevStatus) => ({
        ...prevStatus,
        [formulaId]: {
          isLoading: true,
          progress: 0,
          message: "",
          error: null,
          hasResults: false,
        },
      }));

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        setBacktestStatus((prevStatus) => ({
          ...prevStatus,
          [formulaId]: {
            ...prevStatus[formulaId],
            progress: data.progress,
            message: data.message,
          },
        }));

        if (data.progress === 100) {
          eventSource.close();
          setBacktestStatus((prevStatus) => ({
            ...prevStatus,
            [formulaId]: {
              ...prevStatus[formulaId],
              isLoading: false,
              hasResults: true,
            },
          }));
        }
      };

      eventSource.onerror = () => {
        setBacktestStatus((prevStatus) => ({
          ...prevStatus,
          [formulaId]: {
            ...prevStatus[formulaId],
            isLoading: false,
            error: "Error connecting to backtest service",
          },
        }));
        eventSource.close();
      };
    } catch (error) {
      console.error(`Error starting backtest:`, error);
      setBacktestStatus((prevStatus) => ({
        ...prevStatus,
        [formulaId]: {
          ...prevStatus[formulaId],
          isLoading: false,
          error: "Error starting backtest",
        },
      }));
    }
  };



  const fetchFormulas = async () => {
    try {
      const response = await axios.get(`${ApiUrl.baseURL}/list_formulas`);
      setFormulas(response.data.formulas);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFormulas();
  }, []);


  const prevFormulasRef = useRef(null);

  useEffect(() => {
    // Check for ongoing backtest on component mount
    if (prevFormulasRef.current !== formulas) {
      const formulasWithOngoingBacktest = formulas.filter(
        (formula) =>
          formula.is_backtest_in_progress &&
          formula.backtest_progress_in_percentage < 100
      );

      formulasWithOngoingBacktest.forEach((formula) => {
        console.log("Resuming backtest for formula ID:", formula.id);
        console.log("Current backtest status:", backtestStatus[formula.id]);
        console.log("is_backtest_in_progress:", formula.is_backtest_in_progress);
        console.log("backtest_progress_in_percentage:", formula.backtest_progress_in_percentage);
        handleBacktestResume(formula.id);
      });

      // Update the backtestStatus state with the initial progress and status
      setBacktestStatus((prevStatus) =>
        formulasWithOngoingBacktest.reduce(
          (acc, formula) => ({
            ...acc,
            [formula.id]: {
              isLoading: true,
              progress: formula.backtest_progress_in_percentage,
              message: "Resuming backtest...",
              error: null,
              hasResults: false,
            },
          }),
          prevStatus
        )
      );

      prevFormulasRef.current = formulas;
    }
  }, [formulas, handleBacktestResume]);



  const handleFormulaAction = async (actionType, formulaId) => {
    try {
      switch (actionType) {
        case 'edit':
          navigate(`/editFormula/${formulaId}`);
          break;
        case 'delete':
          await axios.delete(`${ApiUrl.baseURL}/delete_formula?formula_id=${formulaId}`);
          fetchFormulas();
          window.location.reload();
          break;
        case 'duplicate':
          await axios.post(`${ApiUrl.baseURL}/duplicate_formula?formula_id=${formulaId}`);
          fetchFormulas();
          window.location.reload();
          break;
        case 'receiveAlerts':
          const currFormula = formulas.find((formula) => formula.id === formulaId);
          setFormulas((prevFormulas) =>
            prevFormulas.map((formula) => {
              if (formula.id === formulaId) {
                return {
                  ...formula,
                  receiveAlerts: !currFormula.receiveAlerts,
                };
              }
              return formula;
            })
          );
          await axios.patch(`${ApiUrl.baseURL}/switch_alert_on_formula`, { formula_id: formulaId, receive_alerts: !currFormula.receiveAlerts });
      }
    } catch (error) {
      console.error(`Error in ${actionType} formula:`, error);
    }
  };

  const renderRule = (rule) => {
    const parts = [];
    if (rule.exactMatch) {
      parts.push(`Exact Match: ${rule.exactMatch}`);
    } else if (rule.lastMatch) {
      parts.push(`Last Matches ${rule.lastMatch}`);
    }

    if (rule.team_type) {
      parts.push(`Teams: ${rule.team_type === "Away" || rule.team_type === "Home" ?
        `${rule.team_type} Team` : rule.team_type}`);
    }

    if (rule.matchDuration) parts.push(rule.matchDuration);
    if (rule.stats_type) parts.push(`${rule.stats_type}${rule.stats_value ? `: ${rule.stats_value}` : ''}`);
    if (rule.goalDifference !== null) parts.push(`Goal Difference: ${rule.goalDifference}`);
    console.log("rule.comparison_value", rule.comparison_value)
    if (rule.comparison_value === 0) {
      rule.comparison_value = rule.comparison_value.toString();
    }
    if (rule.comparison_value) {
      if (['D', 'W', 'L'].includes(rule.comparison_value)) {
        parts.push({ "D": "Draw", "W": "Won", "L": "Lost" }[rule.comparison_value]);
      } else {
        console.log("Hello")
        console.log("rule.comparison_value-2", rule.comparison_value)
        parts.push(rule.comparison_type ?
          `${rule.comparison_type}: ${rule.comparison_value.hasOwnProperty('min') ?
            `${rule.comparison_value.min} - ${rule.comparison_value.max}` :
            rule.comparison_value}` :
          rule.comparison_value.toString());
        console.log("comparison_value", rule.comparison_value);

      }
    }

    return parts.filter(Boolean).join(", ");
  };

  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress size={60} thickness={4} />
        <Typography variant="h6">Loading formulas...</Typography>
      </LoadingContainer>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}>
        <Typography variant="h5" color="error">Error: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: "#f0f0f0", minHeight: "100vh" }}>
      <Header />
      <Box sx={{ padding: "20px" }}>
        <Box sx={{ marginBottom: "20px" }}>
          <GreenButton variant="contained">Formulas</GreenButton>
          <StyledButton variant="outlined">Backtest Spreadsheet</StyledButton>
          <StyledButton variant="outlined">Spreadsheet</StyledButton>
          <StyledButton variant="outlined" onClick={() => navigate("/addFormulaPage")}>
            Add New Formula
          </StyledButton>
        </Box>

        {formulas.map((formula) => (
          <StyledPaper key={formula.id} elevation={3}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
              <Typography variant="h6">{formula.name}</Typography>
              <Box>
                <ActionButton
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleFormulaAction('delete', formula.id)}
                >
                  Delete
                </ActionButton>
                <ActionButton
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={() => handleFormulaAction('edit', formula.id)}
                >
                  Modify
                </ActionButton>
                <ActionButton
                  startIcon={<ContentCopyIcon />}
                  onClick={() => handleFormulaAction('duplicate', formula.id)}
                >
                  Duplicate
                </ActionButton>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formula.receiveAlerts}
                      onChange={() => {
                        handleFormulaAction('receiveAlerts', formula.id)
                      }}
                    />
                  }
                  label="Receive Alerts"
                />
              </Box>
            </Box>

            {formula.receiveAlerts && (
              <Typography variant="body2">
                PreMatch Alert: {formula.prematchAlertInMinutes === "No alert" ?
                  "No Alert" :
                  `${formula.prematchAlertInMinutes.startsWith("0.") ?
                    parseFloat(formula.prematchAlertInMinutes) * 60 + " minutes" :
                    parseInt(formula.prematchAlertInMinutes) + " hours"} before kickoff`}
              </Typography>
            )}

            <Typography variant="body2">Base: {formula.base}</Typography>

            {formula.rules.map((rule, index) => (
              <Typography key={index} variant="body2" sx={{ color: "#555" }}>
                {renderRule(rule)}
              </Typography>
            ))}


            <Typography variant="body2">
              Selected League(s): {Array.isArray(formula.league) && formula.league.length > 0 ? formula.league.join(", ") : "all"}
            </Typography>
            <Typography variant="body2">
              Excluded League(s): {Array.isArray(formula.excludedLeagues) && formula.excludedLeagues.length > 0 ? formula.excludedLeagues.join(", ") : "None"}
            </Typography>

            {/* Backtest UI */}
            <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
              {backtestStatus[formula.id]?.isLoading ? (
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Box sx={{ flex: 1 }}>
                      <BacktestProgress
                        variant="determinate"
                        value={backtestStatus[formula.id]?.progress || 0}
                      />
                    </Box>
                    <Typography variant="body2" sx={{ minWidth: '45px' }}>
                      <b> {Math.round(backtestStatus[formula.id]?.progress || 0)}% </b>
                    </Typography>
                  </Box>
                  <Button onClick={() => handleReset(formula.id)}>
                    Reset
                  </Button>
                  <Typography variant="body2">
                    {backtestStatus[formula.id]?.message}
                  </Typography>
                </Box>
              ) : (
                <>
                  {(formula.view_results || backtestStatus[formula.id]?.hasResults) ? (
                    <>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                          const url = `/backtest-results/${formula.id}`;
                          const state = encodeURIComponent(JSON.stringify({ downloadResults: formula.download_results }));

                          // Open the URL in a new tab with the state appended as a query parameter
                          window.open(`${url}?state=${state}`, "_blank");
                        }}
                      >
                        View Results
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleBacktestStart(formula.id)}
                      >
                        Recalculate
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => handleBacktestStart(formula.id)}
                    >
                      Start Backtest
                    </Button>
                  )}
                </>
              )}
            </Box>
          </StyledPaper>
        ))}
      </Box>
    </Box>
  );
};

export default FormulaList;