import axios from 'axios';



const Api_url = "http://demo.str-softwares.com/api/v1"
// const Api_url = "http://127.0.0.1:5000/api/v1"
// const Api_url = "/api/v1"
const instance = axios.create({
  baseURL: Api_url,
  // baseURL: '/api/v1'
});

instance.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem('access_token');

    if (token && request.url !== '/access-tokens/refresh') {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

export default instance;

export const ApiUrl = {
  baseURL: Api_url
};
