// src/App.js
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/Home';
import SignIn from './Pages/SignIn/SignIn';
import SoccerTable from './Pages/SoccerTable/SoccerTable';
import FormulaForm from './Pages/FormulaForm';
import Header from './Components/Header';
import BacktestWithFilters from './Components/BackTestTabels';
import FormulaList from './Pages/FormulaList';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../src/theme/theme'; // Import the theme you created
import NotifcationTableRender from './Components/NotificationTable';

function App() {
  return (
    <ThemeProvider theme={theme}> {/* Wrap your app in ThemeProvider */}
      <Router>
        <Routes>
          <Route exact path="/" element={<SignIn />} />
          <Route path="/home" element={<Home />} />
          <Route path="/soccerTable" element={<SoccerTable />} />
          <Route path="/addFormulaPage" element={<FormulaForm />} />
          <Route path="/formulaList" element={<FormulaList />} />
          <Route path="/editFormula/:formulaId" element={<FormulaForm />} />
          <Route path="/backtest-results/:formulaId" element={<BacktestWithFilters />} />
          <Route path="/notification-results/:fixtureId" element={<NotifcationTableRender />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
