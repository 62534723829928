import React, { useRef, useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './SoccerTable.css';
import FilterPopup from '../Filter/FilterPopup';
import soccerListApi from '../../Apis/soccerListApi';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import DownloadIcon from '@mui/icons-material/Download';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import RefreshIcon from '@mui/icons-material/Refresh';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { Grid } from '@mui/material';

function SoccerTable() {
    const [allMatchesData, setAllMatchesData] = useState({}); // Data fetched from server
    const [tableHeaders, setTableHeaders] = useState({})
    const [tableFilters, setTableFilters] = useState({}); // Filters to be sent to server
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const [filterPopupData, setFilterPopupData] = useState(null);
    const [searchKey, setSearchKey] = useState('');
    const [searchInp, setSearchInp] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [reloadPage, setReloadPage] = useState(false);
    const today = new Date();
    const [selectedDate, setSelectedDate] = useState(today);
    const [section, setSection] = useState('L1');
    const [team, setTeam] = useState({ 'home': true, 'away': true });
    const [subSection, setSubSection] = useState({ "FT": true, "1H": true, "2H": true, "AET": true, "PK": true });
    const [dataKeys, setDataKeys] = useState([]);
    const [baseSectionLen, setBaseSectionLen] = useState(0);
    const [lSectionLen, setLSectionLen] = useState(0);
    const menuItems = [];
    const ref = useRef(true);
    for (let i = 1; i <= 20; i++) {
      menuItems.push(<MenuItem key={i} value={'L' + i}>{`L${i}`}</MenuItem>);
    }
    
    const getDateWithFormat = async (date) => {
    
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, '0');
      const dd = String(date.getDate()).padStart(2, '0');
      const formattedDate = `${yyyy}-${mm}-${dd}`;
      return formattedDate;
    }
    
    useEffect(() => {
        const fetchHeaders = async () => {
          try {
            const response_headers = await soccerListApi.teamHeader();
            setTableHeaders(response_headers.data.headers);
            setIsLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
          }
        };
        fetchHeaders();
      }, []);
    
      useEffect(() => {
        setAllMatchesData({});
        setPageNum(1);
        setReloadPage(!reloadPage);
        ref.current = true;
      }, [searchKey, tableFilters, refresh, selectedDate]);
    
      useEffect(() => {
        const fetchData = async () => {
          if(ref.current === false) return
          ref.current = false;
          try {
            let api_payload = { search_string: searchKey, filters: tableFilters, page: pageNum, date: await getDateWithFormat(selectedDate) };
            await refreshPageWithSearchAndFilters(api_payload)
          } catch (error) {
    
          }
        }
        fetchData();
      }, [pageNum, reloadPage]);
    
      useEffect(() => {
        let keys = tableHeaders.main ? [...tableHeaders.main.columns] : [];
        ["FT", "1H", "2H", "AET", "PK"].forEach((key) => {
          if (subSection[key] && tableHeaders.main && tableHeaders.main[key]) {
            keys.push(...tableHeaders.main[key].columns);
          }
        });
        ["FT", "1H", "2H"].forEach((key) => {
          ['home', 'away'].forEach((teamType) => {
            if (subSection[key] && tableHeaders[section] && team[teamType] && tableHeaders[section][teamType][key]) {
              keys.push(...tableHeaders[section][teamType][key].columns);
            }
          })
        });
        setDataKeys(keys);
        let bSecLen = 0;
        if (tableHeaders.main)
          bSecLen = tableHeaders?.main?.columns?.length;
        Object.keys(subSection).forEach((key) => {
          if (subSection[key] && tableHeaders.main && tableHeaders.main[key])
            bSecLen = bSecLen + tableHeaders.main[key].columns.length;
        });
    
        let lSecLen = 0;
        Object.keys(team).forEach((teamType) => {
          Object.keys(subSection).forEach((key) => {
            if (subSection[key] && team[teamType] && tableHeaders[section] && tableHeaders[section][teamType][key])
              lSecLen = lSecLen + tableHeaders[section][teamType][key].columns.length;
          });
        })
        setBaseSectionLen(bSecLen);
        setLSectionLen(lSecLen);
      }, [section, team, subSection, tableHeaders]);
    
      const toggleFilterPopup = async () => {
        await setFilterPopupData(null);
      }
    
      const handleApplyFilter = async (column, inputValue) => {
        setTableFilters({ ...tableFilters, [column]: inputValue });
        setSearchKey(searchInp);
      };
    
      const handleResetFilter = async (column) => {
        let currentFilters = { ...tableFilters };
        delete currentFilters[column];
        setTableFilters(currentFilters);
        setSearchKey(searchInp);
      }
    
      const resetAll = async () => {
        setTableFilters({});
        setSearchInp('');
        setSearchKey('');
        setSelectedDate(today);
      }
    
      const refreshPageWithSearchAndFilters = async (payload) => {
        setIsLoading(true)
    
        const responseData = await soccerListApi.teamData(payload);
        const currentFinishedMatches = allMatchesData.finishedMatches ? allMatchesData.finishedMatches : []
        let allFinishedMatches = [...currentFinishedMatches];
        let finishedMatches = responseData?.data?.finished_matches;
        finishedMatches = finishedMatches ? finishedMatches : [];
        allFinishedMatches.push(...finishedMatches);
        setAllMatchesData({
          'finishedMatches': allFinishedMatches,
          'liveMatches': responseData?.data?.live_matches,
          'upcomingMatches': responseData?.data?.upcoming_matches,
          'totalCount': responseData?.data?.total_number_of_records
        });
        setIsLoading(false);
      }
    
      const submitFresh = async () => {
        setSearchKey(searchInp)
        setRefresh(!refresh);
      }
    
      const downloadFile = async () => {
        try {
          let payload = { search_string: searchKey, filters: tableFilters, page: 1, date: await getDateWithFormat(selectedDate) };
          const response = await soccerListApi.download_team_data(payload);
          const data = response.data;
          const url = window.URL.createObjectURL(new Blob([data]));
    
          // Create a temporary anchor element
          const link = document.createElement('a');
          link.href = url;
    
          // Set the filename you want
          link.setAttribute('download', 'filename.xlsx');
    
          // Append the anchor element to the body
          document.body.appendChild(link);
    
          // Trigger the download
          link.click();
    
          // Cleanup
          link.parentNode.removeChild(link);
    
        } catch (err) {
        }
      }
    
  return (
    <div className='container'>

    {/* Render table */}
    {(
      <div>

        <Grid container spacing={2} sx={{ top: '2rem', position: 'absolute', backgroundColor: '#e6f7fffa', width: '100%', top: 0, left: 0 }}>
          {/* Search row */}
          <Grid item xs={4} className='gridItem' style={{ paddingTop: '16px' }}>
            <TextField label="Search" variant="outlined" value={searchInp} onChange={e => { setSearchInp(e.target.value); setSearchKey(searchInp); }}
              style={{ margin: '8px 8px', fontSize: 'medium', width: '90%' }}
              InputProps={{
                startAdornment: (
                  <SearchIcon style={{ marginRight: '8px', color: 'rgba(0, 0, 0, 0.54)' }} />
                ),
              }} />
          </Grid>
          {/* Section DropDown */}
          <Grid item xs={4} className='gridItem' style={{ paddingTop: '16px' }}>
            <FormControl sx={{ m: 1, minWidth: 200, top: '7px', width: '90%' }}>
              <InputLabel id="demo-simple-select-label">Section</InputLabel>
              <Select
                displayEmpty
                value={section}
                label="Section"
                onChange={(e) => setSection(e.target.value)}
              >
                {menuItems}
              </Select>
              {/* <FormHelperText>Select Section</FormHelperText> */}
            </FormControl>
          </Grid>

          {/* Date  */}
          <Grid item xs={4} style={{ paddingTop: '16px' }}>
            <span color='lblText' className='dateTot'>Date: </span><DatePicker className='dateTot'
              selected={selectedDate} placeholderText='Select Date'
              onChange={(date) => { setSelectedDate(date) }}
              dateFormat="yyyy-MM-dd" />

            <Button item xs={2} variant="contained" style={{ top: '2rem' }} size="small" className='funBtn'
              onClick={downloadFile} startIcon={<DownloadIcon />}></Button>
          </Grid>
          {/* Action Buttons & Loader*/}
          <Grid item xs={4} className='gridItem' sx={{ borderBottomStyle: 'ridge', paddingTop: '4px' }} style={{ padding: '0px 10px' }}>
            <Button onClick={submitFresh} variant="contained" size="large" color="success" className='funBtn' startIcon={<RefreshIcon />}>Refresh</Button>
            <Button variant='contained' color='error' size="large" onClick={resetAll} className='funBtn' startIcon={<RotateLeftIcon />}>Reset</Button>
            {isLoading && <CircularProgress sx={{ marginLeft: '2rem', top: '1rem', position: 'relative' }} thickness={5} size={30} disableShrink color='info' className={'circleDisableShrink'} />}
          </Grid>

          {/* Switch & Checkbox Row */}
          <Grid item xs={4} className='gridItem' sx={{ borderBottomStyle: 'ridge', paddingTop: '4px' }} style={{ padding: '0px 10px' }}>
            {/* Toggle Section start*/}
            {Object.keys(team).map(teamType => (
              <FormControlLabel
                key={teamType}
                control={
                  <Checkbox
                    checked={team[teamType]}
                    onChange={() => setTeam({ ...team, [teamType]: !team[teamType] })} />}
                label={teamType.charAt(0).toUpperCase() + teamType.slice(1)} // Capitalize the label
              />
            ))}

            &nbsp; | &nbsp;
            {Object.keys(subSection).map(section => (

              <FormControlLabel
                key={section}
                control={
                  <Checkbox className='appiledfont'
                    checked={subSection[section]} sx={{ '& .MuiSvgIcon-root': { fontSize: 15 } }}
                    onChange={() => setSubSection(prevState => ({ ...prevState, [section]: !prevState[section] }))}
                    name={section}
                  />
                }
                label={section}
              />
            ))}
            {/* Toggle Section end*/}
          </Grid>
          {/* Count */}
          <Grid item xs={4} sx={{ borderBottomStyle: 'ridge', paddingTop: '4px' }} style={{ padding: '0px 10px' }}>
            <span className='lblText ' > Total Count : {allMatchesData.totalCount} </span>
          </Grid>

          {/* Filters Chips */}
          <Grid item xs={12}> &nbsp; <span style={{ fontSize: 'medium' }}>Applied Filter:</span>
            {Object.keys(tableFilters).map((key) => (
              <Chip
                key={key}
                label={`${key}: ${tableFilters[key]}`}
                size="small" color="secondary"
                onDelete={() => handleResetFilter(key)}
                style={{ marginRight: '5px', marginBottom: '5px', fontWeight: '900', fontSize: 'small', marginLeft: '8px' }}
              />
            ))}
          </Grid>

        </Grid>
        {filterPopupData && <FilterPopup filterObj={filterPopupData} filters={tableFilters} onApply={handleApplyFilter} onReset={handleResetFilter} filterVisible={toggleFilterPopup} />}
        <div className="table-container">
          <table>
            <thead style={{ position: "sticky", top: "50px", backgroundColor: 'white' }}>
              <tr style={{ borderColor: 'black' }}>
                {
                  tableHeaders?.main && <th colSpan={baseSectionLen} rowSpan={2}>{tableHeaders.main.label}</th>
                }
                {
                  tableHeaders[section] && <th colSpan={lSectionLen} rowSpan={1}>{tableHeaders[section].label}</th>
                }
              </tr>
              <tr>
                {
                  ["FT", "1H", "2H"].map((key) => (
                    team['home'] && subSection[key] && tableHeaders[section] && tableHeaders[section]['home'] && tableHeaders[section]['home'][key]
                    && <th key={key} colSpan={tableHeaders[section]['home'][key].columns?.length} rowSpan={1}>{tableHeaders[section]['home'][key].label}</th>
                  ))
                }
                {
                  ["FT", "1H", "2H"].map((key) => (
                    team['away'] && subSection[key] && tableHeaders[section] && tableHeaders[section]['away'] && tableHeaders[section]['away'][key]
                    && <th key={key} colSpan={tableHeaders[section]['away'][key].columns?.length} rowSpan={1}>{tableHeaders[section]['away'][key].label}</th>
                  ))
                }
              </tr>
              <tr>
                {/* Main base keys */}
                {tableHeaders.main?.columns?.map((baseRow, rowIndex) => (
                  <th key={baseRow.key} colSpan={baseRow.colSpan} rowSpan={baseRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                    {baseRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                      setFilterPopupData({ ...baseRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                    }}></i>}
                    <h6 className='headerLbl'>
                      {baseRow.label}  </h6>
                  </th>
                )
                )}
                {/* Main sub keys */}
                {
                  subSection["FT"] && tableHeaders.main && tableHeaders.main["FT"]?.columns?.map((subRow, rowIndex) => (
                    <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                      {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                        setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                      }}></i>}
                      <h6 className='headerLbl'>
                        {subRow.label}  </h6>
                    </th>
                  ))
                }
                {
                  subSection["1H"] && tableHeaders.main && tableHeaders.main["1H"]?.columns?.map((subRow, rowIndex) => (
                    <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                      {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                        setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                      }}></i>}
                      <h6 className='headerLbl'>
                        {subRow.label}  </h6>
                    </th>
                  ))
                }
                {
                  subSection["2H"] && tableHeaders.main && tableHeaders.main["2H"]?.columns?.map((subRow, rowIndex) => (
                    <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                      {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                        setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                      }}></i>}
                      <h6 className='headerLbl'>
                        {subRow.label}  </h6>
                    </th>
                  ))
                }
                {
                  subSection["AET"] && tableHeaders.main && tableHeaders.main["AET"]?.columns?.map((subRow, rowIndex) => (
                    <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                      {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                        setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                      }}></i>}
                      <h6 className='headerLbl'>
                        {subRow.label}  </h6>
                    </th>
                  ))
                }
                {
                  subSection["PK"] && tableHeaders.main && tableHeaders.main["PK"]?.columns?.map((subRow, rowIndex) => (
                    <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                      {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                        setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                      }}></i>}
                      <h6 className='headerLbl'>
                        {subRow.label}  </h6>
                    </th>
                  ))
                }

                {/* lSection subkeys */}
                {
                  team["home"] && subSection["FT"] && tableHeaders[section] && tableHeaders[section]['home']["FT"]?.columns?.map((subRow, rowIndex) => (
                    <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                      {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                        setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                      }}></i>}
                      <h6 className='headerLbl'>
                        {subRow.label}  </h6>
                    </th>
                  ))
                }
                {
                  team["home"] && subSection["1H"] && tableHeaders[section] && tableHeaders[section]['home']["1H"]?.columns?.map((subRow, rowIndex) => (
                    <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                      {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                        setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                      }}></i>}
                      <h6 className='headerLbl'>
                        {subRow.label}  </h6>
                    </th>
                  ))
                }
                {
                  team["home"] && subSection["2H"] && tableHeaders[section] && tableHeaders[section]['home']["2H"]?.columns?.map((subRow, rowIndex) => (
                    <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                      {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                        setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                      }}></i>}
                      <h6 className='headerLbl'>
                        {subRow.label}  </h6>
                    </th>
                  ))
                }
                {
                  team["away"] && subSection["FT"] && tableHeaders[section] && tableHeaders[section]['away']["FT"]?.columns?.map((subRow, rowIndex) => (
                    <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                      {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                        setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                      }}></i>}
                      <h6 className='headerLbl'>
                        {subRow.label}  </h6>
                    </th>
                  ))
                }
                {
                  team["away"] && subSection["1H"] && tableHeaders[section] && tableHeaders[section]['away']["1H"]?.columns?.map((subRow, rowIndex) => (
                    <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                      {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                        setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                      }}></i>}
                      <h6 className='headerLbl'>
                        {subRow.label}  </h6>
                    </th>
                  ))
                }
                {
                  team["away"] && subSection["2H"] && tableHeaders[section] && tableHeaders[section]['away']["2H"]?.columns?.map((subRow, rowIndex) => (
                    <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                      {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                        setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                      }}></i>}
                      <h6 className='headerLbl'>
                        {subRow.label}  </h6>
                    </th>
                  ))
                }


              </tr>
            </thead>
            <tbody>
              {
                !isLoading && allMatchesData.liveMatches?.length > 0 && dataKeys?.length > 0 &&
                <tr>
                  <td key={'live'} colSpan={'100%'}>Live Matches</td>
                </tr>
              }
              {!isLoading && allMatchesData.liveMatches?.map((rowData, rowIndex) => (
                <tr key={rowIndex}>
                  {dataKeys?.map((header, cellIndex) => (
                    <td className="cell" key={header.key} style={{ backgroundColor: header.bColor, color: header.fColor ? header.fColor[rowData[header.key]] : "black" }}>{rowData[header.key]}</td>
                  ))}
                </tr>
              ))}
              {
                !isLoading && allMatchesData.upcomingMatches?.length > 0 && dataKeys?.length > 0 &&
                <tr>
                  <td key={'upcoming'} colSpan={'100%'}>Upcoming Matches</td>
                </tr>
              }
              {!isLoading && allMatchesData.upcomingMatches?.map((rowData, rowIndex) => (
                <tr key={rowIndex}>
                  {dataKeys?.map((header, cellIndex) => (
                    <td className="cell" key={header.key} style={{ backgroundColor: header.bColor, color: header.fColor ? header.fColor[rowData[header.key]] : "black" }}>{rowData[header.key]}</td>
                  ))}
                </tr>
              ))}
              {
                !isLoading && allMatchesData.finishedMatches?.length > 0 && dataKeys?.length > 0 &&
                <tr>
                  <td key={'finished'} colSpan={'100%'}>Finished Matches</td>
                </tr>
              }
              {!isLoading && allMatchesData.finishedMatches?.map((rowData, rowIndex) => (
                <tr key={rowIndex}>
                  {dataKeys?.map((header, cellIndex) => (
                    <td className="cell" key={header.key} style={{ backgroundColor: header.bColor, color: header.fColor ? header.fColor[rowData[header.key]] : "black" }}>{rowData[header.key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <div> < Button id="loadMore" size='medium' onClick={() => { setPageNum(pageNum + 1) }}>Load More</Button>
          </div>
        </div>
      </div>
    )}
  </div>
  )
}

export default SoccerTable