import React, { useRef, useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Home.css';
import FilterPopup from '../Filter/FilterPopup';
import soccerListApi from '../../Apis/soccerListApi';
import { TextField, Autocomplete } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import DownloadIcon from '@mui/icons-material/Download';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import RefreshIcon from '@mui/icons-material/Refresh';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Grid } from '@mui/material';
import { Switch } from '@mui/material';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import { ArrowBackIcon } from '@mui/icons-material';

const TableWithFilters = () => {
  const navigate = useNavigate();
  // State variables
  const [allMatchesData, setAllMatchesData] = useState({}); // Data fetched from server
  const [tableHeaders, setTableHeaders] = useState({})
  const [tableFilters, setTableFilters] = useState({}); // Filters to be sent to server
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [filterPopupData, setFilterPopupData] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const [searchInp, setSearchInp] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [reloadPage, setReloadPage] = useState(false);
  const today = new Date();
  const [selectedDate, setSelectedDate] = useState(today);
  const [section, setSection] = useState('L1');
  const [team, setTeam] = useState({ 'home': true, 'away': true, 'total': true });
  const [subSection, setSubSection] = useState({ "FT": true, "AET": true, "PK": true });
  const [dataKeys, setDataKeys] = useState([]);
  const [baseSectionLen, setBaseSectionLen] = useState(0);
  const [lSectionLen, setLSectionLen] = useState(0);
  const menuItems = [];
  const ref = useRef(true);
  const [downloadFlag, setDownloadFlag] = useState(false); // downloadFlag
  const [suggestions, setSuggestions] = useState([]);
  const [updateTime , setUpdateTime] = useState('');
  for (let i = 1; i <= 20; i++) {
    menuItems.push(<MenuItem key={i} value={'L' + i}>{`L${i}`}</MenuItem>);
  }
  const [apiEndpoint, setapiEndpoint] = useState('/team_data');
  const [downloadEndPoint, setDownloadEndPoint] = useState('/download_team_data');


  const getDateWithFormat = async (date) => {

    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${yyyy}-${mm}-${dd}`;
    return formattedDate;
  }

  useEffect(() => {
    const fetchHeaders = async () => {
      try {
        const response_headers = await soccerListApi.teamHeader();

        setTableHeaders(response_headers.data.headers);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };
    fetchHeaders();
  }, []);

  useEffect(() => {
    ref.current = true;
    setAllMatchesData({});
    setPageNum(1);
    setReloadPage(!reloadPage);
  }, [searchKey, refresh, apiEndpoint]);

  useEffect(() => {
    const fetchData = async () => {
      if (ref.current === false) return
      ref.current = false;
      const suggestionsMap = getSuggestionsMap();
      try {
        let api_payload = { team_id: suggestionsMap[searchKey], filters: tableFilters, page: pageNum, date: await getDateWithFormat(selectedDate) };
        await refreshPageWithSearchAndFilters(api_payload)
      } catch (error) {

      }
    }
    fetchData();
  }, [reloadPage]);

  useEffect(() => {
    // To fetch next page of finished matches

    async function fetchNextPageData() {
      if (pageNum == 1)
        return;
      setIsLoading(true);
      const suggestionsMap = getSuggestionsMap();
      let payload = { team_id: suggestionsMap[searchKey], filters: tableFilters, page: pageNum, date: await getDateWithFormat(selectedDate) };
      const responseData = await soccerListApi.teamData(payload, apiEndpoint, 'finished');
      const currentFinishedMatches = allMatchesData.finishedMatches;
      let allFinishedMatches = [...currentFinishedMatches];
      let finishedMatches = responseData?.data?.finished_matches;
      finishedMatches = finishedMatches ? finishedMatches : [];
      allFinishedMatches.push(...finishedMatches);
      setAllMatchesData({
        ...allMatchesData,
        'finishedMatches': allFinishedMatches
      });
      setIsLoading(false);
    }
    fetchNextPageData();
  }, [pageNum]);

  const getSuggestionsMap = () => {
    let suggestionsMap = {};
    Object.values(suggestions).forEach((option) => {
      suggestionsMap[option.name + " (" + option.id + ")"] = option.id;
    });
    return suggestionsMap;
  }
  const handleChange = (event, newValue) => {
    setSearchInp(newValue);
  };

  const getKeys = (selectedSections) => {
    let keys = tableHeaders.main ? [...tableHeaders.main.columns] : [];
    ["FT", "AET", "PK"].forEach((key) => {
      if (subSection[key] && tableHeaders.main && tableHeaders.main[key]) {
        keys.push(...tableHeaders.main[key].columns);
      }
    });
    selectedSections.forEach((selectedSection) => {
      ['home', 'away', 'total'].forEach((teamType) => {
        ["FT"].forEach((key) => {
          if (subSection[key] && tableHeaders[selectedSection] && team[teamType] && tableHeaders[selectedSection][teamType][key]) {
            keys.push(...tableHeaders[selectedSection][teamType][key].columns);
          }
        })
      })
    });
    return keys;
  }

  useEffect(() => {
    const keys = getKeys([section]);
    setDataKeys(keys);
    let bSecLen = 0;
    if (tableHeaders.main)
      bSecLen = tableHeaders?.main?.columns?.length;
    Object.keys(subSection).forEach((key) => {
      if (subSection[key] && tableHeaders.main && tableHeaders.main[key])
        bSecLen = bSecLen + tableHeaders.main[key].columns.length;
    });

    let lSecLen = 0;
    Object.keys(team).forEach((teamType) => {
      Object.keys(subSection).forEach((key) => {
        if (subSection[key] && team[teamType] && tableHeaders[section] && tableHeaders[section][teamType][key])
          lSecLen = lSecLen + tableHeaders[section][teamType][key].columns.length;
      });
    })
    setBaseSectionLen(bSecLen);
    setLSectionLen(lSecLen);

  }, [section, team, subSection, tableHeaders]);

  // search suggestions
  useEffect(() => {
    const debounce = setTimeout(async () => {
      const suggestionsMap = getSuggestionsMap();
      if (searchInp) {
        if (suggestionsMap[searchInp] === undefined) {
          let response = await soccerListApi.getSearchSuggestions(searchInp);
          const suggestionsData = response.data.data;
          setSuggestions(suggestionsData);
        }
      } else {
        setSuggestions([]);
      }
    }, 500);


    return () => clearTimeout(debounce);
  }, [searchInp]);

  const toggleFilterPopup = async () => {
    await setFilterPopupData(null);
  }

  const handleApplyFilter = async (column, inputValue) => {
    setTableFilters({ ...tableFilters, [column]: inputValue });
    setSearchKey(searchInp);
  };

  const handleResetFilter = async (column) => {
    let currentFilters = { ...tableFilters };
    delete currentFilters[column];
    setTableFilters(currentFilters);
    setSearchKey(searchInp);
  }

  const resetAll = async () => {
    setTableFilters({});
    setSearchInp('');
    setSearchKey('');
    setSelectedDate(today);
  }

  const refreshPageWithSearchAndFilters = async (payload) => {
    setIsLoading(true)

    const responseData = await soccerListApi.teamData(payload, apiEndpoint, '');
    setAllMatchesData({
      'finishedMatches': responseData?.data?.finished_matches,
      'liveMatches': responseData?.data?.live_matches,
      'upcomingMatches': responseData?.data?.upcoming_matches,
      'totalCount': responseData?.data?.total_count,
      'pages': responseData?.data?.page,
      'per_page': responseData?.data?.per_page
    });
    setIsLoading(false);
  }

  const updateLiveScore = async () => {
    if (!allMatchesData.finishedMatches)
      return;
    const suggestionsMap = getSuggestionsMap();
    let payload = { team_id: suggestionsMap[searchKey], filters: tableFilters, page: pageNum, date: await getDateWithFormat(selectedDate) };
    const responseData = await soccerListApi.teamData(payload, apiEndpoint, 'live');
    const liveMatches = responseData?.data?.live_matches
    const updateTime = new Date().toLocaleTimeString();
    console.log("updateTime", updateTime)
    setAllMatchesData({
      ...allMatchesData,
      'liveMatches': liveMatches ? liveMatches : allMatchesData['liveMatches'],
      'lastUpdated': updateTime
    });
  }

  const submitFresh = async () => {
    setSearchKey(searchInp)
    setRefresh(!refresh);
  }

  const downloadFile = async () => {
    try {
      setDownloadFlag(true);
      const suggestionsMap = getSuggestionsMap();
      let api_payload = { team_id: suggestionsMap[searchKey], filters: tableFilters, page: pageNum, date: await getDateWithFormat(selectedDate) };
      const excelHeadersArray = getKeys(Array.from({ length: 20 }, (_, i) => `L${i + 1}`));
      const excelHeadersString = excelHeadersArray.map((header) => header.key).join(',');
      const response = await soccerListApi.download_team_data(api_payload, downloadEndPoint + "?headers=" + excelHeadersString);
      const data = response.data;
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'filename.xlsx');
      document.body.appendChild(link);
      link.click();
      setDownloadFlag(false);
      link.parentNode.removeChild(link);
    } catch (err) {
    }
  }
  return (
    <div className='container'>

      {/* Render table */}
      {(
        <div>

          <Grid container spacing={2} sx={{ top: '2rem', position: 'absolute', backgroundColor: '#e6f7fffa', width: '100%', top: 0, left: 0 }}>
            {/* Search row */}
            <Grid item xs={4} className='gridItem' style={{ paddingTop: '16px' }}>
              <Autocomplete
                freeSolo
                options={suggestions?.map((option) => option.name + " (" + option.id + ")")}
                onChange={handleChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search"
                    variant="outlined"
                    value={searchInp}
                    onChange={(e) => setSearchInp(e.target.value)}
                    style={{ margin: '14px 0px 0px 2rem', fontSize: 'medium', width: '90%' }}
                  />
                )}
              />
            </Grid>
            {/* Section DropDown */}
            <Grid item xs={4} className='gridItem' style={{ paddingTop: '16px' }}>
              <FormControl sx={{ m: 1, minWidth: 200, top: '7px', width: '90%' }}>
                <InputLabel id="demo-simple-select-label">Section</InputLabel>
                <Select
                  displayEmpty
                  value={section}
                  label="Section"
                  onChange={(e) => setSection(e.target.value)}
                >
                  {menuItems}
                </Select>
                {/* <FormHelperText>Select Section</FormHelperText> */}
              </FormControl>
            </Grid>

            {/* Date  */}
            <Grid item xs={4} className='gridItem' style={{ paddingTop: '16px' }}>
              <span color='lblText' className='dateTot'>Date: </span><DatePicker className='dateTot'
                selected={selectedDate} placeholderText='Select Date'
                onChange={(date) => { setSelectedDate(date) }}
                dateFormat="yyyy-MM-dd" />

              {!downloadFlag ? (
                <Button
                  item
                  xs={2}
                  variant="contained"
                  size="small"
                  className='funBtn downLoadBtn'
                  onClick={downloadFile}
                  startIcon={<DownloadIcon />}
                >
                  Download
                </Button>
              ) : (
                <CircularProgress
                  sx={{ marginLeft: '2rem', top: '1rem', position: 'relative' }} thickness={5} size={30} disableShrink color='info' className={'circleDisableShrink'}
                />
              )}
            </Grid>
            {/* Action Buttons & Loader*/}
            <Grid item xs={4} className='gridItem' sx={{ borderBottomStyle: 'ridge', paddingTop: '4px' }} style={{ padding: '0px 10px' }}>
              <Button variant='contained' color='primary' size="small" className='funBtn' onClick={() => setSearchKey(searchInp)} startIcon={<SearchIcon />}>Search</Button>
              <Button onClick={submitFresh} variant="contained" size="small" color="success" className='funBtn' startIcon={<RefreshIcon />}>Refresh</Button>
              <Button variant='contained' color='error' size="small" onClick={resetAll} className='funBtn' startIcon={<RotateLeftIcon />}>Reset</Button>
              <Button variant='contained' color='warning' size="small" onClick={updateLiveScore} className='funBtn' startIcon={<AutorenewIcon />}>Refresh Live</Button>
              {isLoading && <CircularProgress sx={{ marginLeft: '2rem', top: '1rem', position: 'relative' }} thickness={5} size={30} disableShrink color='info' className={'circleDisableShrink'} />}
            </Grid>

            {/* Switch & Checkbox Row */}
            <Grid item xs={4} className='gridItem' sx={{ borderBottomStyle: 'ridge', paddingTop: '4px' }} style={{ padding: '0px 40px' }}>
              {/* Toggle Section start*/}
              {Object.keys(team).map(teamType => (
                <FormControlLabel
                  key={teamType}
                  control={
                    <Checkbox
                      checked={team[teamType]}
                      onChange={() => setTeam({ ...team, [teamType]: !team[teamType] })} />}
                  label={teamType.charAt(0).toUpperCase() + teamType.slice(1)}
                />
              ))}

              &nbsp; | &nbsp;
              {Object.keys(subSection).map(section => (

                <FormControlLabel
                  key={section}
                  control={
                    <Checkbox className='appiledfont'
                      checked={subSection[section]} sx={{ '& .MuiSvgIcon-root': { fontSize: 15 } }}
                      onChange={() => setSubSection(prevState => ({ ...prevState, [section]: !prevState[section] }))}
                      name={section}
                    />
                  }
                  label={section}
                />
              ))}
             <b>Filter Live Matches : {allMatchesData?.lastUpdated ? allMatchesData?.lastUpdated : '' }</b>
              {/* Toggle Section end*/}
            </Grid>

            {/* Count */}
            <Grid item xs={4} className='gridItem' sx={{ borderBottomStyle: 'ridge', paddingTop: '4px' }} style={{ padding: '0px 18px' }}>
              <span className='lblText ' > Total Count : {allMatchesData.totalCount} </span> <br />
              <div style={{ display: 'inline-flex' }}>
                <p style={{ margin: 0 }} className='appiledfont'>
                  <span>All</span>
                  <Switch
                    checked={apiEndpoint === '/other_team_data'}
                    onChange={() => {
                      const flag = apiEndpoint === '/team_data';
                      setapiEndpoint(flag ? '/other_team_data' : '/team_data');
                      setDownloadEndPoint(flag ? '/download_other_team_data' : '/download_team_data');
                    }}
                  />
                  <span>Home Vs Away</span>
                </p>
              </div>
              <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={() => window.open('/formulaList', '_blank')}
                  style={{
                    marginTop: "5px",
                    marginBottom: "5px",
                    marginLeft: "25px",
                  }}
                >
                  Formulas
                </Button>

            </Grid>

            {/* Filters Chips */}
            <Grid item xs={12}> &nbsp; <span style={{ fontSize: 'medium' }}>Applied Filter:</span>
              {Object.keys(tableFilters).map((key) => (
                <Chip
                  key={key}
                  label={`${key}: ${tableFilters[key]}`}
                  size="small" color="secondary"
                  onDelete={() => handleResetFilter(key)}
                  style={{ marginRight: '5px', marginBottom: '5px', fontWeight: '900', fontSize: 'small', marginLeft: '8px' }}
                />
              ))}
            </Grid>

          </Grid>

          {/* start from here for total as home */}
          {filterPopupData && <FilterPopup filterObj={filterPopupData} filters={tableFilters} onApply={handleApplyFilter} onReset={handleResetFilter} filterVisible={toggleFilterPopup} />}
          <div className="table-container">
            <table>
              <thead style={{ position: "sticky", top: "50px", backgroundColor: 'white' }}>
                <tr style={{ borderColor: 'black' }}>
                  {
                    tableHeaders?.main && <th colSpan={baseSectionLen} rowSpan={2}>{tableHeaders.main.label}</th>
                  }
                  {
                    tableHeaders[section] && <th colSpan={lSectionLen} rowSpan={1}>{tableHeaders[section].label}</th>
                  }
                </tr>
                <tr>
                  {
                    ["FT"].map((key) => (
                      team['home'] && subSection[key] && tableHeaders[section] && tableHeaders[section]['home'] && tableHeaders[section]['home'][key]
                      && <th key={key} colSpan={tableHeaders[section]['home'][key].columns?.length} rowSpan={1}>{tableHeaders[section]['home'][key].label}</th>
                    ))
                  }
                  {
                    ["FT"].map((key) => (
                      team['away'] && subSection[key] && tableHeaders[section] && tableHeaders[section]['away'] && tableHeaders[section]['away'][key]
                      && <th key={key} colSpan={tableHeaders[section]['away'][key].columns?.length} rowSpan={1}>{tableHeaders[section]['away'][key].label}</th>
                    ))
                  }
                  {
                    ["FT"].map((key) => (
                      team['total'] && subSection[key] && tableHeaders[section] && tableHeaders[section]['total'] && tableHeaders[section]['total'][key]
                      && <th key={key} colSpan={tableHeaders[section]['total'][key].columns?.length} rowSpan={1}>{tableHeaders[section]['total'][key].label}</th>
                    ))
                  }
                </tr>
                <tr>
                  {/* Main base keys */}
                  {tableHeaders.main?.columns?.map((baseRow, rowIndex) => (
                    <th key={baseRow.key} colSpan={baseRow.colSpan} rowSpan={baseRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                      {baseRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                        setFilterPopupData({ ...baseRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                      }}></i>}
                      <h6 className='headerLbl'>
                        {baseRow.label}  </h6>
                    </th>
                  )
                  )}
                  {/* Main sub keys */}
                  {
                    subSection["FT"] && tableHeaders.main && tableHeaders.main["FT"]?.columns?.map((subRow, rowIndex) => (
                      <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                        {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                          setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                        }}></i>}
                        <h6 className='headerLbl'>
                          {subRow.label}  </h6>
                      </th>
                    ))
                  }


                  {
                    subSection["AET"] && tableHeaders.main && tableHeaders.main["AET"]?.columns?.map((subRow, rowIndex) => (
                      <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                        {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                          setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                        }}></i>}
                        <h6 className='headerLbl'>
                          {subRow.label}  </h6>
                      </th>
                    ))
                  }
                  {
                    subSection["PK"] && tableHeaders.main && tableHeaders.main["PK"]?.columns?.map((subRow, rowIndex) => (
                      <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                        {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                          setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                        }}></i>}
                        <h6 className='headerLbl'>
                          {subRow.label}  </h6>
                      </th>
                    ))
                  }

                  {/* lSection subkeys */}
                  {/* home FT 1H 2H */}
                  {
                    team["home"] && subSection["FT"] && tableHeaders[section] && tableHeaders[section]['home']["FT"]?.columns?.map((subRow, rowIndex) => (
                      <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                        {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                          setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                        }}></i>}
                        <h6 className='headerLbl'>
                          {subRow.label}  </h6>
                      </th>
                    ))
                  }

                  {
                    team["home"] && tableHeaders[section] && tableHeaders[section]['home']?.columns?.map((subRow, rowIndex) => (
                      <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                        {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                          setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                        }}></i>}
                        <h6 className='headerLbl'>
                          {subRow.label}  </h6>
                      </th>
                    ))
                  }
                  {/* away FT 1H 2H */}
                  {
                    team["away"] && subSection["FT"] && tableHeaders[section] && tableHeaders[section]['away']["FT"]?.columns?.map((subRow, rowIndex) => (
                      <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                        {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                          setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                        }}></i>}
                        <h6 className='headerLbl'>
                          {subRow.label}  </h6>
                      </th>
                    ))
                  }
                  {
                    team["away"] && tableHeaders[section] && tableHeaders[section]['away']?.columns?.map((subRow, rowIndex) => (
                      <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                        {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                          setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                        }}></i>}
                        <h6 className='headerLbl'>
                          {subRow.label}  </h6>
                      </th>
                    ))
                  }
                  {
                    team["away"] && tableHeaders[section] && tableHeaders[section]['away']?.columns?.map((subRow, rowIndex) => (
                      <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                        {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                          setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                        }}></i>}
                        <h6 className='headerLbl'>
                          {subRow.label}  </h6>
                      </th>
                    ))
                  }
                  {/* total FT 1H 2H */}
                  {
                    team["total"] && subSection["FT"] && tableHeaders[section] && tableHeaders[section]['total']["FT"]?.columns?.map((subRow, rowIndex) => (
                      <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                        {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                          setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                        }}></i>}
                        <h6 className='headerLbl'>
                          {subRow.label}  </h6>
                      </th>
                    ))
                  }

                  {
                    team["total"] && tableHeaders[section] && tableHeaders[section]['total']?.columns?.map((subRow, rowIndex) => (
                      <th key={subRow.key} colSpan={subRow.colSpan} rowSpan={subRow.rowSpan} style={{ padding: '0px', margin: '0px', whiteSpace: 'nowrap' }}>
                        {subRow.filter && <i className="fa fa-filter headingfilter" style={{ margin: '8px 1px' }} onClick={async (e) => {
                          setFilterPopupData({ ...subRow, 'top': (100 * e.clientY / window.innerHeight) + '%', 'left': (100 * e.clientX / window.innerWidth) + '%' })
                        }}></i>}
                        <h6 className='headerLbl'>
                          {subRow.label}  </h6>
                      </th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {
                  allMatchesData.liveMatches?.length > 0 && dataKeys?.length > 0 &&
                  <tr>
                    <td key={'live'} colSpan={'100%'}>Live Matches</td>
                  </tr>
                }
                {allMatchesData.liveMatches?.map((rowData, rowIndex) => (
                  <tr key={rowIndex}>
                    {dataKeys?.map((header, cellIndex) => (
                      <td className="cell" key={header.key} style={{ backgroundColor: header.bColor, color: header.fColor ? header.fColor[rowData[header.key]] : (header.liveColor || "black") }}>{rowData[header.key]}</td>
                    ))}
                  </tr>
                ))}
                {
                  allMatchesData.upcomingMatches?.length > 0 && dataKeys?.length > 0 &&
                  <tr>
                    <td key={'upcoming'} colSpan={'100%'}>Upcoming Matches</td>
                  </tr>
                }
                {allMatchesData.upcomingMatches?.map((rowData, rowIndex) => (
                  <tr key={rowIndex}>
                    {dataKeys?.map((header, cellIndex) => (
                      <td className="cell" key={header.key} style={{ backgroundColor: header.bColor, color: header.fColor ? header.fColor[rowData[header.key]] : "black" }}>{rowData[header.key]}</td>
                    ))}
                  </tr>
                ))}
                {
                  allMatchesData.finishedMatches?.length > 0 && dataKeys?.length > 0 &&
                  <tr>
                    <td key={'finished'} colSpan={'100%'}>Finished Matches</td>
                  </tr>
                }
                {allMatchesData.finishedMatches?.map((rowData, rowIndex) => (
                  <tr key={rowIndex}>
                    {dataKeys?.map((header, cellIndex) => (
                      <td className="cell" key={header.key} style={{ backgroundColor: header.bColor, color: header.fColor ? header.fColor[rowData[header.key]] : "black" }}>{rowData[header.key]}</td>

                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            {!isLoading && <div> {allMatchesData.pages && ((allMatchesData.pages * allMatchesData.per_page) < allMatchesData.totalCount) && < Button id="loadMore" size='medium' onClick={() => { setPageNum(pageNum + 1) }}>Load More</Button>}
            </div>}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '30px',
                position: 'relative'
              }}
            >
              {isLoading && <CircularProgress
                sx={{ thickness: 5 }}
                size={30}
                disableShrink
                color='info'
              />}
            </Box>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableWithFilters;