import instance from "../Auth/basicConfig";
const loginUser = async(data) =>{
    return callApi( async () => {return await instance.post('login',data)})
}
async function callApi(fun) {
    try {
        let response = await fun();
        if (response.name === "AxiosError")
            response = response.response;
        if(response.status === 422 || response.status === 401){
            return {authFailed:true, data:null,message:""}
        }
        else if(response.status === 200){
            return {authFailed:false, data:response.data,message:"success"}
        }
        return {authFailed:false, data:null, message:"API failed with status: " + response.status}
    } catch (error) {
        return {authFailed:false, data:null, message:"API failed with error: " + error}
    }
}

export default {loginUser}