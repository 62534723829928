import { useMemo, useCallback, useState } from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  Box,
  CircularProgress,
  Checkbox,
  ListItemText,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const SearchableSelectMultiple = ({
  label,
  value = [],
  onChange,
  onSelectionChange,
  items = [],
  defaultValue,
  defaultLabel,
  isLoading,
  countryIds,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const getItemId = (item) => {
    if (!item) return null;
    // console.log(item);
    return item.countryId || item.leagueId;
  };


  const selectedItemNames = useMemo(() => {
    if (!Array.isArray(value) || value.length === 0) return "";

    return value
      .filter(Boolean) // Filter out null values
      .map((id) => {
        const item = items.find((item) => {
          const itemId = getItemId(item);
          return itemId && itemId.toString() === id.toString(); // Ensure both are compared as strings
        });
        return item ? item.name : null;
      })
      .filter(Boolean)
      .join(", ");
  }, [value, items]);


  const filteredItems = useMemo(() => {
    if (!Array.isArray(items)) return [];
    if (!searchQuery.trim()) return items;
    return items.filter((item) =>
      item?.name?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [items, searchQuery]);

  const handleChange = useCallback(
    (event) => {
      const {
        target: { value: selectedValues },
      } = event;

      // Ensure we're working with an array
      const newValue =
        typeof selectedValues === "string"
          ? selectedValues.split(",")
          : [...selectedValues];

      // If "All" (defaultValue) is selected, clear the selection
      if (newValue.includes(defaultValue)) {
        // onChange([]);
        return;
      }

      // Filter out any null, undefined, or defaultValue entries
      const filteredValue = newValue.filter((v) => v && v !== defaultValue);

      // Only update if we have valid values
      if (filteredValue.length > 0 || newValue.length === 0) {
        // onChange(filteredValue);
      }
    },
    [defaultValue]
  );

  const handleSearchChange = (event) => {
    event.stopPropagation();
    setSearchQuery(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={Array.isArray(value) ? value.filter(Boolean) : []}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={() =>
          value.includes(defaultValue) || value.length === 0
            ? defaultLabel
            : selectedItemNames
        }
        MenuProps={{
          PaperProps: {
            style: { maxHeight: 400 },
          },
        }}
      >
        <Box
          sx={{
            p: 1,
            position: "sticky",
            top: 0,
            bgcolor: "background.paper",
            zIndex: 1,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <TextField
            fullWidth
            size="small"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyDown={(e) => e.stopPropagation()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {isLoading ? (
          <MenuItem disabled>
            <Box display="flex" alignItems="center" gap={1}>
              <CircularProgress size={20} />
              <span>Loading...</span>
            </Box>
          </MenuItem>
        ) : (
          filteredItems.map((item) => {
            const itemId = getItemId(item);
            if (!itemId || !item.name) return null;
            const isChecked = Array.isArray(value) && value.includes(itemId);
            return (
              <MenuItem key={itemId} value={itemId} onClick={() => onSelectionChange(itemId, !isChecked)} dense>
                <Checkbox
                  checked={isChecked}
                  sx={{ padding: "4px" }}
                />
                <ListItemText
                  primary={item.name}
                  sx={{ margin: 0, padding: "0 8px" }}
                />
              </MenuItem>
            );
          })
        )}
      </Select>
    </FormControl>
  );
};

export default SearchableSelectMultiple;
