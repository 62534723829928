import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  MoreHoriz as MoreHorizIcon
} from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import InfoIcon from "@mui/icons-material/Info";
import RefreshIcon from "@mui/icons-material/Refresh";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Grid, IconButton, Switch, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import axios from "axios";
import { XIcon } from "lucide-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import soccerListApi from "../Apis/soccerListApi";
import { ApiUrl } from "../Auth/basicConfig";
import FilterPopup from "../Pages/Filter/FilterPopup";
import "../Pages/Home/Home.css";
import BacktestProgress from "./NotificationTable";

const ExpandableRow = ({
  rowData,
  dataKeys,
  isExpanded,
  onToggle,
  formulaId,
  selectedFixtureIds = [], // Array to store fixture IDs
  setSelectedFixtureIds, // Function to update fixture IDs
  totalMatchingFixtures = 0, // Running total of matching fixtures
  setTotalMatchingFixtures, // Function to update total
}) => {
  const [matchingData, setMatchingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const ITEMS_PER_PAGE = 15;
  const [AtcurrentPage, setAtCurrentPage] = useState(null);
  const [totalPage, setTotalPage] = useState(null);


  const LargeTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      minHeight: "50px",
      height: "auto",
      padding: "10px",
      fontSize: "1.2rem",
      fontSize: "1rem",
    },
  }));

  // Handle checkbox change for selecting fixtures
  const handleCheckboxChange = (e) => {
    const fixtureId = rowData.fixture_id;
    const matchingCount = rowData.count_of_matching_fixtures || 0;

    if (e.target.checked) {
      // Add fixture ID to selected list if not already present
      setSelectedFixtureIds((prevIds) =>
        prevIds.includes(fixtureId) ? prevIds : [...prevIds, fixtureId]
      );

      // Add count to total only if not already counted
      setTotalMatchingFixtures(
        (prevTotal) =>
          prevTotal +
          (selectedFixtureIds.includes(fixtureId) ? 0 : matchingCount)
      );
    } else {
      // Remove fixture ID from selected list
      setSelectedFixtureIds((prevIds) =>
        prevIds.filter((id) => id !== fixtureId)
      );

      // Subtract count from total
      setTotalMatchingFixtures((prevTotal) => prevTotal - matchingCount);
    }
  };

  // Rest of your existing code remains the same...
  // Fetch matching data from the API
  const fetchMatchingData = async (page = 1) => {
    setIsLoading(true);
    try {
      const response = await soccerListApi.getMatchingTeamData({
        main_fixture_ids: rowData.fixture_id,
        formula_id: formulaId,
        page: page,
        limit: ITEMS_PER_PAGE,
      });

      if (
        response.data &&
        response.data.message === "No matching records found"
      ) {
        setError("No matching records found");
        setIsLoading(false);
        return;
      }

      if (response.data === null) {
        setError("No matching records found");
        setIsLoading(false);
        return;
      }

      if (response.data.message) {
        setError(response.data.message);
        setMatchingData([]);
      } else {
        const cleanData = response.data;
        const firstKey = Object.keys(cleanData)[0];
        const fixtureData = cleanData[firstKey] || [];
        setTotalPage(response.data.total_pages);
        setAtCurrentPage(response.data.page);

        setMatchingData((prevData) =>
          page === 1 ? fixtureData : [...prevData, ...fixtureData]
        );

        setHasMore(fixtureData.length === ITEMS_PER_PAGE);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching matching data");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isExpanded) {
      setMatchingData([]);
      setCurrentPage(1);
      setError(null);
      setHasMore(true);
    }
  }, [isExpanded]);

  useEffect(() => {
    if (isExpanded && rowData.fixture_id && formulaId) {
      setCurrentPage(1);
      fetchMatchingData(1);
    }
  }, [isExpanded, rowData.fixture_id, formulaId]);

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    fetchMatchingData(nextPage);
  };

  const handleToggle = (e) => {
    e.preventDefault();
    onToggle();
  };

  const mapDataToHeader = (matchRow, header) => {
    if (matchRow.hasOwnProperty(header.key)) {
      return matchRow[header.key];
    }

    switch (header.type) {
      case "date":
        return matchRow.starting_at_timestamp
          ? new Date(matchRow.starting_at_timestamp * 1000).toLocaleDateString()
          : "";
      case "time":
        return matchRow.time || "";
      case "status":
        return matchRow.match_status || "";
      default:
        const possibleKeys = Object.keys(matchRow);
        const matchingKey = possibleKeys.find(
          (key) => key.toLowerCase() === header.key.toLowerCase()
        );
        return matchingKey ? matchRow[matchingKey] : "";
    }
  };

  return (
    <>
      <LargeTooltip title={`Row ${rowData.rowIndex}`} placement="top" arrow>
        <tr
          style={{
            backgroundColor: isExpanded ? "#E3F2FD" : "inherit",
            transition: "background-color 0.2s ease",
          }}
        >
          <td style={{ width: "40px", padding: "0", textAlign: "center" }}>
            <Checkbox
              checked={selectedFixtureIds.includes(rowData.fixture_id)}
              onChange={handleCheckboxChange}
            />
          </td>
          <td style={{ width: "40px", padding: "0", textAlign: "center" }}>
            <IconButton size="small" onClick={handleToggle} sx={{ ml: 1 }}>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </td>
          {dataKeys?.map((header) => (
            <td
              className="cell"
              key={header.key}
              style={{
                backgroundColor: header.bColor,
                color: header.fColor
                  ? header.fColor[rowData[header.key]]
                  : "black",
              }}
            >
              {rowData[header.key]}
            </td>
          ))}
        </tr>
      </LargeTooltip>

      {/* Rest of the component remains the same... */}
      {isExpanded && (
        <>
          {matchingData.map((matchRow, index) => (
            <tr key={`match-${index}`} style={{ backgroundColor: "#F5F5F5" }}>
              <td
                style={{
                  width: "40px",
                  textAlign: "center",
                  color: "#666",
                  fontSize: "0.9em",
                }}
              ></td>

              <td
                style={{
                  width: "40px",
                  textAlign: "center",
                  color: "#666",
                  fontSize: "0.9em",
                }}
              >
                {index + 1}
              </td>
              {dataKeys?.map((header) => (
                <td
                  className="cell"
                  key={header.key}
                  style={{
                    color: header.fColor
                      ? header.fColor[matchRow[header.key]]
                      : "black",
                  }}
                >
                  {mapDataToHeader(matchRow, header)}
                </td>
              ))}
            </tr>
          ))}

          {/* Loading state */}
          {isLoading && (
            <tr>
              <td colSpan={dataKeys.length + 1}>
                <Box sx={{ display: "flex", ml: 5, py: 2 }}>
                  <CircularProgress size={24} />
                </Box>
              </td>
            </tr>
          )}

          {/* Error state */}
          {error && (
            <tr>
              <td colSpan={dataKeys.length + 1}>
                <Box sx={{ ml: 5, py: 2, color: "error.main" }}>{error}</Box>
              </td>
            </tr>
          )}

          {/* Empty state */}
          {!isLoading && !error && matchingData.length === 0 && (
            <tr>
              <td colSpan={dataKeys.length + 1}>
                <Box sx={{ ml: 5, py: 2, color: "text.secondary" }}>
                  No matching team data found
                </Box>
              </td>
            </tr>
          )}

          {/* Load more button */}
          {!isLoading && !error && matchingData.length > 0 && hasMore && (
            <tr>
              <td colSpan={dataKeys.length + 1}>
                <b>
                  <p>
                    Page: {AtcurrentPage} of Total Pages: {totalPage}
                  </p>
                </b>
                <Box sx={{ ml: 5, py: 2 }}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleLoadMore}
                    startIcon={<MoreHorizIcon />}
                    sx={{
                      textTransform: "none",
                      borderRadius: 1,
                      pl: 2,
                      pr: 2,
                    }}
                  >
                    Load More
                  </Button>
                </Box>
              </td>
            </tr>
          )}
        </>
      )}
    </>
  );
};

const renderRule = (rule) => {
  const parts = [];
  if (rule.exactMatch) {
    parts.push(`Exact Match: ${rule.exactMatch}`);
  } else if (rule.lastMatch) {
    parts.push(`Last Matches ${rule.lastMatch}`);
  }

  if (rule.team_type) {
    parts.push(
      `Teams: ${rule.team_type === "Away" || rule.team_type === "Home"
        ? `${rule.team_type} Team`
        : rule.team_type
      }`
    );
  }

  if (rule.matchDuration) parts.push(rule.matchDuration);
  if (rule.stats_type)
    parts.push(
      `${rule.stats_type}${rule.stats_value ? `: ${rule.stats_value}` : ""}`
    );
  if (rule.goalDifference !== null)
    parts.push(`Goal Difference: ${rule.goalDifference}`);
  if (rule.comparison_value === 0) {
    rule.comparison_value = rule.comparison_value.toString();
  }
  if (rule.comparison_value) {
    if (["D", "W", "L"].includes(rule.comparison_value)) {
      parts.push({ D: "Draw", W: "Won", L: "Lost" }[rule.comparison_value]);
    } else {
      parts.push(
        rule.comparison_type
          ? `${rule.comparison_type}: ${rule.comparison_value.hasOwnProperty("min")
            ? `${rule.comparison_value.min} - ${rule.comparison_value.max}`
            : rule.comparison_value
          }`
          : rule.comparison_value.toString()
      );
    }
  }

  return parts.filter(Boolean).join(", ");
};

const BacktestWithFilters = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { formulaId } = useParams();
  const downloadResults = location.state?.downloadResults;
  // State variables
  const [allMatchesData, setAllMatchesData] = useState({}); // Data fetched from server
  const [tableHeaders, setTableHeaders] = useState({});
  const [tableFilters, setTableFilters] = useState({}); // Filters to be sent to server
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [filterPopupData, setFilterPopupData] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [searchInp, setSearchInp] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [reloadPage, setReloadPage] = useState(false);
  const today = new Date();
  const [selectedDate, setSelectedDate] = useState(today);
  const [section, setSection] = useState("L1");
  const [team, setTeam] = useState({ home: true, away: true, total: true });
  const [subSection, setSubSection] = useState({
    FT: true,
    AET: true,
    PK: true,
  });
  const [dataKeys, setDataKeys] = useState([]);
  const [baseSectionLen, setBaseSectionLen] = useState(0);
  const [lSectionLen, setLSectionLen] = useState(0);
  const menuItems = [];
  const [suggestions, setSuggestions] = useState([]);
  const [formulas, setFormulas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [backtestStatus, setBacktestStatus] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);
  const [downloadingStates, setDownloadingStates] = useState({});
  const [selectedFixtureIds, setSelectedFixtureIds] = useState([]);
  const [totalMatchingFixtures, setTotalMatchingFixtures] = useState(0);

  const [expandedRows, setExpandedRows] = useState(new Set());

  const toggleRow = (rowId) => {
    const newExpandedRows = new Set(expandedRows);
    if (newExpandedRows.has(rowId)) {
      newExpandedRows.delete(rowId);
    } else {
      newExpandedRows.add(rowId);
    }
    setExpandedRows(newExpandedRows);
  };

  for (let i = 1; i <= 20; i++) {
    menuItems.push(<MenuItem key={i} value={"L" + i}>{`L${i}`}</MenuItem>);
  }
  const [backTestOnly, setBackTestOnly] = useState(false);
  const fetchFormulas = async () => {
    try {
      const response = await axios.get(
        `${ApiUrl.baseURL}/list_formulas?formula_id=${formulaId}`
      );
      setFormulas(response.data.formula);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFormulas();
  }, []);

  const handleMouseEnter = useCallback(() => {
    const timer = setTimeout(() => setShowTooltip(true), 100);
    return () => clearTimeout(timer);
  }, []);

  const handleMouseLeave = useCallback(() => {
    const timer = setTimeout(() => setShowTooltip(false), 100);
    return () => clearTimeout(timer);
  }, []);

  const handleDownloadStart = async (formulaId) => {
    try {
      const action = "excel";
      const prepareExcelPayload = {
        required_main_fixture_ids: selectedFixtureIds,
      };

      // START BACKTEST
      await axios.post(
        `${ApiUrl.baseURL}/backtest?formula_id=${formulaId}&action=${action}`,
        prepareExcelPayload
      );

      // SETUP SSE CONNECTION
      const eventSource = new EventSource(
        `${ApiUrl.baseURL}/backtest?formula_id=${formulaId}&action=${action}`
      );

      setBacktestStatus((prevStatus) => ({
        ...prevStatus,
        [formulaId]: {
          isLoading: true,
          progress: 0,
          message: "",
          error: null,
          hasResults: false,
        },
      }));

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        setBacktestStatus((prevStatus) => ({
          ...prevStatus,
          [formulaId]: {
            ...prevStatus[formulaId],
            progress: data.progress,
            message: data.message,
          },
        }));

        if (data.progress === 100) {
          eventSource.close();
          setBacktestStatus((prevStatus) => ({
            ...prevStatus,
            [formulaId]: {
              ...prevStatus[formulaId],
              isLoading: false,
              hasResults: true,
            },
          }));
        }
      };

      eventSource.onerror = () => {
        setBacktestStatus((prevStatus) => ({
          ...prevStatus,
          [formulaId]: {
            ...prevStatus[formulaId],
            isLoading: false,
            error: "Error connecting to backtest service",
          },
        }));
        eventSource.close();
      };
    } catch (error) {
      console.error(`Error starting backtest:`, error);
      setBacktestStatus((prevStatus) => ({
        ...prevStatus,
        [formulaId]: {
          ...prevStatus[formulaId],
          isLoading: false,
          error: "Error starting backtest",
        },
      }));
    }
  };

  const handleDownloadResume = async (formulaId) => {
    try {
      const action = "excel";
      const eventSource = new EventSource(
        `${ApiUrl.baseURL}/backtest?formula_id=${formulaId}&action=${action}`
      );

      setBacktestStatus((prevStatus) => ({
        ...prevStatus,
        [formulaId]: {
          isLoading: true,
          progress: 0,
          message: "",
          error: null,
          hasResults: false,
        },
      }));

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        setBacktestStatus((prevStatus) => ({
          ...prevStatus,
          [formulaId]: {
            ...prevStatus[formulaId],
            progress: data.progress,
            message: data.message,
          },
        }));

        if (data.progress === 100) {
          eventSource.close();
          setBacktestStatus((prevStatus) => ({
            ...prevStatus,
            [formulaId]: {
              ...prevStatus[formulaId],
              isLoading: false,
              hasResults: true,
            },
          }));
        }
      };

      eventSource.onerror = () => {
        setBacktestStatus((prevStatus) => ({
          ...prevStatus,
          [formulaId]: {
            ...prevStatus[formulaId],
            isLoading: false,
            error: "Error connecting to backtest service",
          },
        }));
        eventSource.close();
      };
    } catch (error) {
      console.error(`Error starting backtest:`, error);
      setBacktestStatus((prevStatus) => ({
        ...prevStatus,
        [formulaId]: {
          ...prevStatus[formulaId],
          isLoading: false,
          error: "Error starting backtest",
        },
      }));
    }
  };

  const handleDownloadXLSX = async (formulaId, formula_name) => {
    try {
      // Set downloading state to true for this specific formula
      setDownloadingStates((prev) => ({
        ...prev,
        [formulaId]: true,
      }));

      // Request the data from the API, assuming it returns an XLSX file
      const response = await axios.post(
        `${ApiUrl.baseURL}/download_backtest_results?formula_id=${formulaId}`,
        {},
        { responseType: "arraybuffer" }
      );

      // Convert the response data (ArrayBuffer) into a Blob
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      // Generate the download link with the correct file extension
      link.href = url;
      const now = new Date();
      const formattedDate = `${now.getFullYear()}_${String(now.getMonth() + 1).padStart(2, "0")}_${String(
        now.getDate()
      ).padStart(2, "0")}_${String(now.getHours()).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}`;

      link.download = `Backtest_${formula_name}_${formattedDate}.xlsx`;

      // Trigger the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading XLSX:", error);
    } finally {
      // Reset downloading state after a short delay to ensure the user sees the status
      setTimeout(() => {
        setDownloadingStates((prev) => ({
          ...prev,
          [formulaId]: false,
        }));
      }, 1000);
    }
  };

  const getDateWithFormat = async (date) => {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${yyyy}-${mm}-${dd}`;
    return formattedDate;
  };

  const prevFormulaRef = useRef(null);

  useEffect(() => {
    // Check for ongoing backtest on component mount
    if (prevFormulaRef.current !== formulas && formulas) {
      // Check if this single formula has an ongoing backtest
      const hasOngoingBacktest =
        formulas.is_excel_in_progress &&
        formulas.excel_progress_in_percentage < 100;

      if (hasOngoingBacktest) {
        // Resume the backtest
        handleDownloadResume(formulas.id);

        // Update the backtestStatus state for this formula
        setBacktestStatus((prevStatus) => ({
          ...prevStatus,
          [formulas.id]: {
            isLoading: true,
            progress: formulas.excel_progress_in_percentage,
            message: "Preparing excel...",
            error: null,
            hasResults: false,
          },
        }));
      }

      prevFormulaRef.current = formulas;
    }
  }, [formulas, handleDownloadResume]);

  useEffect(() => {
    const fetchHeaders = async () => {
      try {
        const response_headers = await soccerListApi.teamHeader();

        setTableHeaders(response_headers.data.headers);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };
    fetchHeaders();
  }, []);

  useEffect(() => {
    setAllMatchesData({});
    setPageNum(1);
    setReloadPage((prev) => !prev);
  }, [searchKey, refresh, backTestOnly]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const responseData = await getPageData();
        setAllMatchesData({
          finishedMatches: responseData?.data?.finished_matches || [],
          totalCount: responseData?.data?.total_count,
          pages: responseData?.data?.page,
          per_page: responseData?.data?.per_page,
          total_page: responseData?.data?.total_pages,
        });
      } catch (error) {
        setError("Error fetching data: " + error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [reloadPage]);

  useEffect(() => {
    // To fetch next page of finished matches
    async function fetchNextPageData() {
      if (pageNum == 1) return;
      try {
        setIsLoading(true);
        const responseData = await getPageData();
        const currentFinishedMatches = allMatchesData.finishedMatches;
        let allFinishedMatches = [...currentFinishedMatches];
        let finishedMatches = responseData?.data?.finished_matches;
        finishedMatches = finishedMatches ? finishedMatches : [];
        allFinishedMatches.push(...finishedMatches);
        setAllMatchesData({
          ...allMatchesData,
          finishedMatches: allFinishedMatches,
          total_page: responseData?.data?.total_pages,
          pages: responseData?.data?.page,
        });
      } catch (error) {
        setError("Error fetching data: " + error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchNextPageData();
  }, [pageNum]);

  const getSuggestionsMap = () => {
    let suggestionsMap = {};
    Object.values(suggestions).forEach((option) => {
      suggestionsMap[option.name + " (" + option.id + ")"] = option.id;
    });
    return suggestionsMap;
  };
  const handleChange = (event, newValue) => {
    setSearchInp(newValue);
  };

  const getKeys = (selectedSections) => {
    let keys = tableHeaders.main ? [...tableHeaders.main.columns] : [];
    ["FT", "AET", "PK"].forEach((key) => {
      if (subSection[key] && tableHeaders.main && tableHeaders.main[key]) {
        keys.push(...tableHeaders.main[key].columns);
      }
    });
    selectedSections.forEach((selectedSection) => {
      ["home", "away", "total"].forEach((teamType) => {
        ["FT"].forEach((key) => {
          if (
            subSection[key] &&
            tableHeaders[selectedSection] &&
            team[teamType] &&
            tableHeaders[selectedSection][teamType][key]
          ) {
            keys.push(...tableHeaders[selectedSection][teamType][key].columns);
          }
        });
      });
    });
    return keys;
  };

  useEffect(() => {
    const keys = getKeys([section]);
    setDataKeys(keys);
    let bSecLen = 2;
    if (tableHeaders.main) bSecLen += tableHeaders?.main?.columns?.length;
    Object.keys(subSection).forEach((key) => {
      if (subSection[key] && tableHeaders.main && tableHeaders.main[key])
        bSecLen = bSecLen + tableHeaders.main[key].columns.length;
    });

    let lSecLen = 0;
    Object.keys(team).forEach((teamType) => {
      Object.keys(subSection).forEach((key) => {
        if (
          subSection[key] &&
          team[teamType] &&
          tableHeaders[section] &&
          tableHeaders[section][teamType][key]
        )
          lSecLen =
            lSecLen + tableHeaders[section][teamType][key].columns.length;
      });
    });
    setBaseSectionLen(bSecLen);
    setLSectionLen(lSecLen);
  }, [section, team, subSection, tableHeaders]);

  // search suggestions
  useEffect(() => {
    const debounce = setTimeout(async () => {
      const suggestionsMap = getSuggestionsMap();
      if (searchInp) {
        if (suggestionsMap[searchInp] === undefined) {
          let response = await soccerListApi.getSearchSuggestions(searchInp);
          const suggestionsData = response.data.data;
          setSuggestions(suggestionsData);
        }
      } else {
        setSuggestions([]);
      }
    }, 500);

    return () => clearTimeout(debounce);
  }, [searchInp]);

  const toggleFilterPopup = async () => {
    await setFilterPopupData(null);
  };

  const handleApplyFilter = async (column, inputValue) => {
    setTableFilters({ ...tableFilters, [column]: inputValue });
    setSearchKey(searchInp);
  };

  const handleResetFilter = async (column) => {
    let currentFilters = { ...tableFilters };
    delete currentFilters[column];
    setTableFilters(currentFilters);
    setSearchKey(searchInp);
  };

  const resetAll = async () => {
    setTableFilters({});
    setSearchInp("");
    setSearchKey("");
    setSelectedDate(today);
  };

  const getPageData = async () => {
    const suggestionsMap = getSuggestionsMap();
    let payload = {
      team_id: suggestionsMap[searchKey],
      filters: tableFilters,
      page: pageNum,
      date: await getDateWithFormat(selectedDate),
      backtest_only: backTestOnly
    };
    return await soccerListApi.teamDataBacktest(
      payload,
      "/team_data",
      "finished",
      formulaId
    );
  };

  const submitFresh = async () => {
    setSearchKey(searchInp);
    setRefresh(!refresh);
  };

  const renderTableBody = () => {
    return (
      <tbody>
        {allMatchesData.liveMatches?.length > 0 && dataKeys?.length > 0 && (
          <tr>
            <td colSpan={dataKeys.length + 1}>Live Matches</td>
          </tr>
        )}
        {allMatchesData.liveMatches?.map((rowData) => (
          <ExpandableRow
            key={rowData.fixture_id}
            rowData={rowData}
            dataKeys={dataKeys}
            isExpanded={expandedRows.has(rowData.fixture_id)}
            onToggle={() => toggleRow(rowData.fixture_id)}
            formulaId={formulaId}
            selectedFixtureIds={selectedFixtureIds}
            setSelectedFixtureIds={setSelectedFixtureIds}
            totalMatchingFixtures={totalMatchingFixtures}
            setTotalMatchingFixtures={setTotalMatchingFixtures}
          />
        ))}

        {allMatchesData.upcomingMatches?.length > 0 && dataKeys?.length > 0 && (
          <tr>
            <td colSpan={dataKeys.length + 1}>Upcoming Matches</td>
          </tr>
        )}
        {allMatchesData.upcomingMatches?.map((rowData) => (
          <ExpandableRow
            key={rowData.fixture_id}
            rowData={rowData}
            dataKeys={dataKeys}
            isExpanded={expandedRows.has(rowData.fixture_id)}
            onToggle={() => toggleRow(rowData.fixture_id)}
            formulaId={formulaId}
            selectedFixtureIds={selectedFixtureIds}
            setSelectedFixtureIds={setSelectedFixtureIds}
            totalMatchingFixtures={totalMatchingFixtures}
            setTotalMatchingFixtures={setTotalMatchingFixtures}
          />
        ))}

        {allMatchesData.finishedMatches?.length > 0 && dataKeys?.length > 0 && (
          <tr>
            <td colSpan={dataKeys.length + 1}>Finished Matches</td>
          </tr>
        )}
        {allMatchesData.finishedMatches?.map((rowData, index) => (
          <ExpandableRow
            key={rowData.fixture_id}
            rowData={{ ...rowData, rowIndex: index + 1 }}
            dataKeys={dataKeys}
            isExpanded={expandedRows.has(rowData.fixture_id)}
            onToggle={() => toggleRow(rowData.fixture_id)}
            formulaId={formulaId}
            selectedFixtureIds={selectedFixtureIds}
            setSelectedFixtureIds={setSelectedFixtureIds}
            totalMatchingFixtures={totalMatchingFixtures}
            setTotalMatchingFixtures={setTotalMatchingFixtures}
          />
        ))}
      </tbody>
    );
  };

  const renderTableHeader = ({
    data = [], // Default to empty array if not provided
    selectedFixtureIds,
    setSelectedFixtureIds,
    totalMatchingFixtures,
    setTotalMatchingFixtures,
  }) => {
    return (
      <thead
        style={{ position: "sticky", top: "50px", backgroundColor: "white" }}
      >
        {/* Second row - Section headers */}
        <tr style={{ borderColor: "black" }}>
          {tableHeaders?.main && (
            <th colSpan={baseSectionLen} rowSpan={2}>
              {tableHeaders.main.label}
            </th>
          )}
          {tableHeaders[section] && (
            <th colSpan={lSectionLen} rowSpan={1}>
              {tableHeaders[section].label}
            </th>
          )}
        </tr>

        {/* Third row - Team type headers (home/away/total) */}
        <tr>
          {["FT"].map((key) => (
            <>
              {team["home"] &&
                subSection[key] &&
                tableHeaders[section]?.["home"]?.[key] && (
                  <th
                    key={`home-${key}`}
                    colSpan={tableHeaders[section]["home"][key].columns?.length}
                    rowSpan={1}
                  >
                    {tableHeaders[section]["home"][key].label}
                  </th>
                )}
              {team["away"] &&
                subSection[key] &&
                tableHeaders[section]?.["away"]?.[key] && (
                  <th
                    key={`away-${key}`}
                    colSpan={tableHeaders[section]["away"][key].columns?.length}
                    rowSpan={1}
                  >
                    {tableHeaders[section]["away"][key].label}
                  </th>
                )}
              {team["total"] &&
                subSection[key] &&
                tableHeaders[section]?.["total"]?.[key] && (
                  <th
                    key={`total-${key}`}
                    colSpan={
                      tableHeaders[section]["total"][key].columns?.length
                    }
                    rowSpan={1}
                  >
                    {tableHeaders[section]["total"][key].label}
                  </th>
                )}
            </>
          ))}
        </tr>

        {/* Fourth row - Column headers */}
        <tr>
          {/* Main base columns */}
          <>
            {/* Empty first cell */}
            <th style={{ padding: "0px", margin: "0px", whiteSpace: "nowrap" }}>
              <Checkbox
                // Checked if all possible fixtures are selected
                checked={
                  selectedFixtureIds.length > 0 &&
                  selectedFixtureIds.length === data.length
                }
                // Indeterminate if some (but not all) fixtures are selected
                indeterminate={
                  selectedFixtureIds.length > 0 &&
                  selectedFixtureIds.length < data.length
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    // Select all fixtures
                    const allFixtureIds = data.map((row) => row.fixture_id);
                    const totalCount = data.reduce(
                      (total, row) =>
                        total + (row.count_of_matching_fixtures || 0),
                      0
                    );

                    setSelectedFixtureIds(allFixtureIds);
                    setTotalMatchingFixtures(totalCount);
                  } else {
                    // Deselect all fixtures
                    setSelectedFixtureIds([]);
                    setTotalMatchingFixtures(0);
                  }
                }}
              />
            </th>
            <th
              style={{ padding: "0px", margin: "0px", whiteSpace: "nowrap" }}
            ></th>

            {/* Shifted content */}
            {tableHeaders.main?.columns?.map((baseRow) => (
              <th
                key={baseRow.key}
                colSpan={baseRow.colSpan}
                rowSpan={baseRow.rowSpan}
                style={{ padding: "0px", margin: "0px", whiteSpace: "nowrap" }}
              >
                {baseRow.filter && (
                  <i
                    className="fa fa-filter headingfilter"
                    style={{ margin: "8px 1px" }}
                    onClick={(e) => {
                      setFilterPopupData({
                        ...baseRow,
                        top: `${(100 * e.clientY) / window.innerHeight}%`,
                        left: `${(100 * e.clientX) / window.innerWidth}%`,
                      });
                    }}
                  />
                )}
                <h6 className="headerLbl">{baseRow.label}</h6>
              </th>
            ))}
          </>

          {/* Render FT, AET, PK subsections */}
          {["FT", "AET", "PK"].map(
            (sectionType) =>
              subSection[sectionType] &&
              tableHeaders.main?.[sectionType]?.columns?.map((subRow) => (
                <th
                  key={`${sectionType}-${subRow.key}`}
                  colSpan={subRow.colSpan}
                  rowSpan={subRow.rowSpan}
                  style={{
                    padding: "0px",
                    margin: "0px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {subRow.filter && (
                    <i
                      className="fa fa-filter headingfilter"
                      style={{ margin: "8px 1px" }}
                      onClick={(e) => {
                        setFilterPopupData({
                          ...subRow,
                          top: `${(100 * e.clientY) / window.innerHeight}%`,
                          left: `${(100 * e.clientX) / window.innerWidth}%`,
                        });
                      }}
                    />
                  )}
                  <h6 className="headerLbl">{subRow.label}</h6>
                </th>
              ))
          )}

          {/* Team-specific columns (home/away/total) */}
          {["home", "away", "total"].map(
            (teamType) =>
              team[teamType] && (
                <>
                  {/* FT sections */}
                  {subSection["FT"] &&
                    tableHeaders[section]?.[teamType]?.["FT"]?.columns?.map(
                      (subRow) => (
                        <th
                          key={`${teamType}-ft-${subRow.key}`}
                          colSpan={subRow.colSpan}
                          rowSpan={subRow.rowSpan}
                          style={{
                            padding: "0px",
                            margin: "0px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {subRow.filter && (
                            <i
                              className="fa fa-filter headingfilter"
                              style={{ margin: "8px 1px" }}
                              onClick={(e) => {
                                setFilterPopupData({
                                  ...subRow,
                                  top: `${(100 * e.clientY) / window.innerHeight
                                    }%`,
                                  left: `${(100 * e.clientX) / window.innerWidth
                                    }%`,
                                });
                              }}
                            />
                          )}
                          <h6 className="headerLbl">{subRow.label}</h6>
                        </th>
                      )
                    )}

                  {/* Regular columns */}
                  {tableHeaders[section]?.[teamType]?.columns?.map((subRow) => (
                    <th
                      key={`${teamType}-regular-${subRow.key}`}
                      colSpan={subRow.colSpan}
                      rowSpan={subRow.rowSpan}
                      style={{
                        padding: "0px",
                        margin: "0px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {subRow.filter && (
                        <i
                          className="fa fa-filter headingfilter"
                          style={{ margin: "8px 1px" }}
                          onClick={(e) => {
                            setFilterPopupData({
                              ...subRow,
                              top: `${(100 * e.clientY) / window.innerHeight}%`,
                              left: `${(100 * e.clientX) / window.innerWidth}%`,
                            });
                          }}
                        />
                      )}
                      <h6 className="headerLbl">{subRow.label}</h6>
                    </th>
                  ))}
                </>
              )
          )}
        </tr>
      </thead>
    );
  };

  const handleReset = async (formulaId) => {
    try {
      const action = "excel";
      await axios.get(`${ApiUrl.baseURL}/reset_backtest?formula_id=${formulaId}&action=${action}`);
      window.location.reload()
    } catch (error) {
      // Log error
      console.error(`Error resetting backtest for formula ID ${formulaId}:`, error);
    }
  };

  return (
    <div className="Parent_container">
      <div className="container">
        {/* Render table */}
        {
          <div>
            <Grid
              container
              spacing={2}
              sx={{
                top: "2rem",
                position: "absolute",
                backgroundColor: "#e6f7fffa",
                top: 0,
                left: 0,
              }}
            >
              {/* Search row */}
              <Grid
                item
                xs={4}
                className="gridItem"
                style={{ paddingTop: "16px" }}
              >
                <Autocomplete
                  freeSolo
                  options={suggestions?.map(
                    (option) => option.name + " (" + option.id + ")"
                  )}
                  onChange={handleChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search"
                      variant="outlined"
                      value={searchInp}
                      onChange={(e) => setSearchInp(e.target.value)}
                      style={{
                        margin: "14px 0px 0px 2rem",
                        fontSize: "medium",
                        width: "90%",
                      }}
                    />
                  )}
                />
              </Grid>
              {/* Section DropDown */}
              <Grid
                item
                xs={4}
                className="gridItem"
                style={{ paddingTop: "16px" }}
              >
                <FormControl
                  sx={{ m: 1, minWidth: 200, top: "7px", width: "90%" }}
                >
                  <InputLabel id="demo-simple-select-label">Section</InputLabel>
                  <Select
                    displayEmpty
                    value={section}
                    label="Section"
                    onChange={(e) => setSection(e.target.value)}
                  >
                    {menuItems}
                  </Select>
                  {/* <FormHelperText>Select Section</FormHelperText> */}
                </FormControl>
              </Grid>

              {/* Date  */}
              <Grid
                item
                xs={4}
                className="gridItem"
                style={{ paddingTop: "16px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2, // Space between date picker and button/progress
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <span color="lblText" className="dateTot">
                      Date:{" "}
                    </span>
                    <DatePicker
                      className="dateTot"
                      selected={selectedDate}
                      placeholderText="Select Date"
                      onChange={(date) => {
                        setSelectedDate(date);
                      }}
                      dateFormat="yyyy-MM-dd"
                    />
                  </Box>

                  {formulas && (
                    <>
                      {(() => {
                        const status = backtestStatus[formulas.id];
                        const hasResults =
                          formulas.download_results || status?.hasResults;

                        if (status?.isLoading) {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "150px", // Match button width
                                height: "32px", // Match button height
                                justifyContent: "center",
                              }}
                            >
                              <BacktestProgress
                                variant="determinate"
                                value={status?.progress || 0}
                              />
                              <Typography
                                variant="caption"
                                sx={{
                                  fontSize: "11px",
                                  textAlign: "center",
                                  mt: 0.5,
                                }}
                              >
                                {status?.message}
                              </Typography>
                            </Box>
                          );
                        }

                        if (hasResults) {
                          return downloadingStates[formulas.id] ? (
                            <Typography
                              variant="body2"
                              sx={{
                                color: "primary.main",
                                display: "flex",
                                alignItems: "center",
                                fontSize: "16px",
                                gap: 1,
                              }}
                            >
                              Downloading started
                            </Typography>
                          ) : (
                            <>
                              <Button
                                variant="contained"
                                size="small"
                                className="funBtn_backtest"
                                onClick={() => handleDownloadStart(formulas.id)}
                                style={{ height: "34px" }}
                              >
                                Prepare Excel
                              </Button>
                              <Button
                                variant="contained"
                                size="small"
                                startIcon={<DownloadIcon />}
                                className="funBtn_backtest"
                                color="primary"
                                onClick={() =>
                                  handleDownloadXLSX(formulas.id, formulas.name)
                                }
                                style={{ height: "34px" }}
                              >
                                Download Excel
                              </Button>
                            </>
                          );
                        }

                        return (
                          <Button
                            variant="contained"
                            size="small"
                            startIcon={<DownloadIcon />}
                            className="funBtn downLoadBtn"
                            onClick={() => handleDownloadStart(formulas.id)}
                          >
                            Prepare Excel
                          </Button>
                        );
                      })()}
                    </>
                  )}
                </Box>
              </Grid>
              {/* Action Buttons & Loader*/}
              <Grid
                item
                xs={4}
                className="gridItem"
                sx={{ borderBottomStyle: "ridge", paddingTop: "4px" }}
                style={{ padding: "0px 10px" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="funBtn"
                  onClick={() => setSearchKey(searchInp)}
                  startIcon={<SearchIcon />}
                >
                  Search
                </Button>
                <Button
                  onClick={submitFresh}
                  variant="contained"
                  size="small"
                  color="success"
                  className="funBtn"
                  startIcon={<RefreshIcon />}
                >
                  Refresh
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={resetAll}
                  className="funBtn"
                  startIcon={<RotateLeftIcon />}
                >
                  Reset
                </Button>
                {isLoading && (
                  <CircularProgress
                    sx={{
                      marginLeft: "2rem",
                      top: "1rem",
                      position: "relative",
                    }}
                    thickness={5}
                    size={30}
                    disableShrink
                    color="info"
                    className="circleDisableShrink"
                  />
                )}

                <IconButton
                  size="small"
                  sx={{
                    marginLeft: "0.5rem",
                    top: "1rem",
                    position: "relative",
                  }}
                  onClick={() => setShowTooltip(!showTooltip)}
                >
                  <InfoIcon color="info" fontSize="medium" />
                  {showTooltip && (
                    <div
                      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center"
                      onClick={(e) => {
                        if (e.target === e.currentTarget) {
                          setShowTooltip(false);
                        }
                      }}
                    >
                      <div
                        className="bg-white rounded-lg shadow-xl max-w-md w-full mx-4"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Box sx={{ position: "relative", p: 3 }}>
                          <IconButton
                            size="small"
                            onClick={() => setShowTooltip(false)}
                            sx={{
                              position: "absolute",
                              right: 8,
                              top: 8,
                            }}
                          >
                            <XIcon fontSize="small" />
                          </IconButton>

                          <Typography variant="h6" sx={{ mb: 2, pr: 4 }}>
                            {"Formula Name: "}
                            {formulas.name}
                          </Typography>

                          {formulas["receiveAlerts"] && (
                            <Typography variant="body2" sx={{ mb: 1 }}>
                              PreMatch Alert:{" "}
                              {formulas.prematchAlertInMinutes === "No alert"
                                ? "No Alert"
                                : `${formulas.prematchAlertInMinutes.startsWith(
                                  "0."
                                )
                                  ? parseFloat(
                                    formulas.prematchAlertInMinutes
                                  ) *
                                  60 +
                                  " minutes"
                                  : parseInt(
                                    formulas.prematchAlertInMinutes
                                  ) + " hours"
                                } before kickoff`}
                            </Typography>
                          )}

                          <Typography variant="body2" sx={{ mb: 1 }}>
                            Base: {formulas.base}
                          </Typography>

                          {formulas.rules.map((rule, index) => (
                            <Typography
                              key={index}
                              variant="body2"
                              sx={{
                                color: "#555",
                                mb: 0.5,
                              }}
                            >
                              {"Rule " + (index + 1) + ": "}
                              {renderRule(rule)}
                            </Typography>
                          ))}
                        </Box>
                      </div>
                    </div>
                  )}
                </IconButton>
              </Grid>

              {/* Switch & Checkbox Row */}
              <Grid
                item
                xs={4}
                className="gridItem"
                sx={{ borderBottomStyle: "ridge", paddingTop: "4px" }}
                style={{ padding: "0px 40px" }}
              >
                {/* Toggle Section start*/}
                {Object.keys(team).map((teamType) => (
                  <FormControlLabel
                    key={teamType}
                    control={
                      <Checkbox
                        checked={team[teamType]}
                        onChange={() =>
                          setTeam({ ...team, [teamType]: !team[teamType] })
                        }
                      />
                    }
                    label={teamType.charAt(0).toUpperCase() + teamType.slice(1)}
                  />
                ))}
                &nbsp; | &nbsp;
                {Object.keys(subSection).map((section) => (
                  <FormControlLabel
                    key={section}
                    control={
                      <Checkbox
                        className="appiledfont"
                        checked={subSection[section]}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 15 } }}
                        onChange={() =>
                          setSubSection((prevState) => ({
                            ...prevState,
                            [section]: !prevState[section],
                          }))
                        }
                        name={section}
                      />
                    }
                    label={section}
                  />
                ))}
                {/* Toggle Section end*/}
              </Grid>

              {/* Count */}
              <Grid
                item
                xs={4}
                className="gridItem"
                sx={{ borderBottomStyle: "ridge", paddingTop: "4px" }}
                style={{ padding: "0px 18px" }}
              >
                <span className="lblText">
                  Total Count: {allMatchesData.totalCount}
                </span>{" "}

                {formulas && (
                  <>
                    {(() => {
                      const status = backtestStatus[formulas.id];

                      if (status?.isLoading) {
                        return (
                          <Button onClick={() => handleReset(formulas.id)}>
                            Reset
                          </Button>
                        );
                      }

                      return null;
                    })()}
                  </>
                )}

                <br></br>
                {formulas.calculate_total_count && (
                  <span className="lblText">
                    Actual Count: {formulas.actual_total_count} {" | "}
                  </span>
                )}
                <span className="lblText">
                  {" "}
                  &nbsp;Selected Count: {totalMatchingFixtures}
                </span>
                <br />
                <div style={{ display: "inline-flex" }}>
                  <p style={{ margin: 0 }} className="appiledfont">
                    <span>All</span>
                    <Switch
                      checked={backTestOnly}
                      onChange={() => setBackTestOnly(!backTestOnly)}
                    />
                    <span>Back Test Only</span>
                  </p>
                </div>
              </Grid>

              {/* Filters Chips */}
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => navigate("/formulaList")}
                    startIcon={<ArrowBackIcon />}
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: "25px",
                    }}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item xs>
                  <span style={{ fontSize: "medium" }}>Applied Filter:</span>
                  {Object.keys(tableFilters).map((key) => (
                    <Chip
                      key={key}
                      label={`${key}: ${tableFilters[key]}`}
                      size="small"
                      color="secondary"
                      onDelete={() => handleResetFilter(key)}
                      style={{
                        marginRight: "5px",
                        marginBottom: "5px",
                        fontWeight: "900",
                        fontSize: "small",
                        marginLeft: "8px",
                      }}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>

            {/* start from here for total as home */}
            {filterPopupData && (
              <FilterPopup
                filterObj={filterPopupData}
                filters={tableFilters}
                onApply={handleApplyFilter}
                onReset={handleResetFilter}
                filterVisible={toggleFilterPopup}
              />
            )}
            <div className="table-container">
              <table>
                {renderTableHeader({
                  data: allMatchesData.finishedMatches || [], // Use allMatchesData.data or fallback to empty array
                  selectedFixtureIds,
                  setSelectedFixtureIds,
                  totalMatchingFixtures,
                  setTotalMatchingFixtures,
                })}
                {renderTableBody()}
              </table>
              {!isLoading && (
                <div>
                  {" "}
                  {allMatchesData.pages &&
                    allMatchesData.pages * allMatchesData.per_page <
                    allMatchesData.totalCount && (
                      <>
                        <p
                          style={{
                            margin: "10px 0",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Page {allMatchesData.pages} of Total Pages:{" "}
                          {allMatchesData.total_page}
                        </p>
                        <Button
                          id="loadMore"
                          size="medium"
                          onClick={() => {
                            setPageNum(pageNum + 1);
                          }}
                        >
                          Load More
                        </Button>
                      </>
                    )}
                </div>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "30px",
                  position: "relative",
                }}
              >
                {isLoading && (
                  <CircularProgress
                    sx={{ thickness: 5 }}
                    size={30}
                    disableShrink
                    color="info"
                  />
                )}
              </Box>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default BacktestWithFilters;
