import { React } from "react";
import {
  Typography,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  FormLabel,
  FormHelperText,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";


const Rule = ({ index, rule, updateRule, removeRule, errors, duplicateRule }) => {
  const handleUpdate = (updates) => {
    const newRule = { ...rule, ...updates };

    // Reset dependent values when changing stats type
    if (updates.stats_type) {
      newRule.valueFrom = "";
      newRule.valueTo = "";
      newRule.comparison_type = "equal";
      newRule.wdlValue = "";
    }

    updateRule(index, newRule); // Pass both index and new rule data
  };

  const handleDuplicateRule = () => {
    if (duplicateRule) {
      duplicateRule(index);
    }
  };

  const getExactMatchStats = () => [
    { value: "Total Goals", label: "Total Goals (TG)" },
    { value: "Goal Scored", label: "Goal Scored (GS)" },
    { value: "Goal Conceeded", label: "Goal Conceeded (GC)" },
    { value: "Goal Difference", label: "Goal Difference (GDif)" },
    { value: "Won/Draw/Lost", label: "Won/Draw/Lost" },
  ];

  const getExactMatchPlusMinusStats = () => [
    { value: "Total Goals", label: "Total Goals (TG) -/+" },
    { value: "Goal Scored", label: "Goal Scored (GS) -/+" },
    { value: "Goal Conceeded", label: "Goal Conceeded (GC) -/+" },
    { value: "Goal Difference", label: "Goal Difference (GDif) -/+" },
    { value: "Won/Draw/Lost", label: "Won/Draw/Lost -/+" },
  ];

  const getLastMatchStats = () => [
    { value: "Total Goals Row", label: "Total Goals Row (TGR)" },
    { value: "Goal Scored Row", label: "Goal Scored Row (GSR)" },
    { value: "Goal Conceeded Row", label: "Goal Conceeded Row (GCR)" },
    { value: "Goal Difference Row", label: "Goal Difference Row (GDifR)" },
    { value: "Points Row", label: "Points Row (PTSR)" },
    { value: "Won/Draw/Lost Row", label: "Won/Draw/Lost Row (WDLR)" },
    { value: "Goal Scored Over Row", label: "Goal Scored Over Row (GSOR)" },
    {
      value: "Goal Conceeded Over Row",
      label: "Goal Conceeded Over Row (GCOR)",
    },
    { value: "Total Over Row", label: "Total Over Row (TOR)" },
    { value: "Total BTTS Row", label: "Total BTTS Row (TBTTSR)" },
  ];

  const getLastMatchPlusMinusStats = () => [
    { value: "Total Goals Row", label: "Total Goals Row (TGR) -/+" },
    { value: "Goal Scored Row", label: "Goal Scored Row (GSR) -/+" },
    { value: "Goal Conceeded Row", label: "Goal Conceeded Row (GCR) -/+" },
    { value: "Goal Difference Row", label: "Goal Difference Row (GDifR) -/+" },
    { value: "Points Row", label: "Points Row (PTSR) -/+" },
    { value: "Won/Draw/Lost Row", label: "Won/Draw/Lost Row (WDLR) -/+" },
    { value: "Goal Scored Over Row", label: "Goal Scored Over Row (GSOR) -/+" },
    {
      value: "Goal Conceeded Over Row",
      label: "Goal Conceeded Over Row (GCOR) -/+",
    },
    { value: "Total Over Row", label: "Total Over Row (TOR) -/+" },
    { value: "Total BTTS Row", label: "Total BTTS Row (TBTTSR) -/+" },
  ];

  const getErrorMessage = (fieldName) => {
    if (!errors || !errors[index] || !errors[index][fieldName]) return null;
    return errors[index][fieldName];
  };

  const hasError = (fieldName) => {
    return Boolean(getErrorMessage(fieldName));
  };

  const getStatsOptions = () => {
    if (rule.stats_mode === "plusMinus" && rule.matchType === "exact") {
      return getExactMatchPlusMinusStats();
    }
    if (rule.stats_mode === "plusMinus" && rule.matchType === "last") {
      return getLastMatchPlusMinusStats();
    }
    if (rule.stats_mode === "normal" && rule.matchType === "exact") {
      return getExactMatchStats();
    }
    if (rule.stats_mode === "normal" && rule.matchType === "last") {
      return getLastMatchStats();
    }
    return [];
  };

  const getRangeOptions = (stats_type) => {
    const baseType = stats_type?.replace("_PM", "");
    switch (baseType) {
      case "Goal Difference":
      case "Goal Difference Row":
        return { min: -30, max: 30, step: 1 };
      case "Points Row":
        return { min: 0, max: 120, step: 1 };
      case "Won/Draw/Lost Row":
      case "Goal Scored Over Row":
        return { min: 0, max: 40, step: 1 };
      case "Total Over Row":
      case "Total BTTS Row":
        return { min: 0, max: 40, step: 1 };
      case "Goal Scored Row":
      case "Goal Conceeded Row":
        return { min: 0, max: 200, step: 1 };
      default:
        return { min: 0, max: 30, step: 1 };
    }
  };

  const validateRange = (value, range) => {
    if (value === "") return "";
    const numValue = parseInt(value);
    if (isNaN(numValue)) return range.min;
    return Math.min(Math.max(numValue, range.min), range.max);
  };

  const renderWDLDropdown = () => {
    return (
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Select Result</InputLabel>
        <Select
          value={rule.wdlValue || ""}
          onChange={(e) => handleUpdate({ wdlValue: e.target.value })}
          label="Select Result"
        >
          <MenuItem value="Won">Won</MenuItem>
          <MenuItem value="Draw">Draw</MenuItem>
          <MenuItem value="Lost">Lost</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const renderWDLDropdown_with_single_value = () => {
    return (
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Select Result</InputLabel>
        <Select
          value={rule.wdlValue || ""}
          onChange={(e) => handleUpdate({ wdlValue: e.target.value })}
          label="Select Result"
        >
          <MenuItem value="W">Won</MenuItem>
          <MenuItem value="D">Draw</MenuItem>
          <MenuItem value="L">Lost</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const renderPlusMinusFields = () => {
    const baseType = rule.stats_type?.replace("_PM", "");
    const showOverOptions = [
      "Total Over Row",
      "Goal Scored Over Row",
      "Goal Conceeded Over Row",
      "Total BTTS Row",
    ].some((type) => baseType?.includes(type));

    // Check for both WDL conditions
    const showWDL = baseType === "Won/Draw/Lost Row";
    const exact_wdl = baseType === "Won/Draw/Lost";

    const handleSelectChange = (e) => {
      handleUpdate({ plusMinusRange: e.target.value });
    };

    // Function to render the selected value
    const renderValue = (selected) => {
      if (selected === 0) return "0 (Exact)";
      if (selected > 0) return `-${selected} - ${selected}`;
      return selected; // For WDL options
    };

    return (
      <>
        {showOverOptions && renderOverOptions(baseType)}

        {/* Render WDL dropdown for both conditions */}
        {showWDL && renderWDLDropdown()}

        {/* Plus/minus range selector */}
        <FormControl sx={{ minWidth: 120 }} size="medium">
          <InputLabel id="plus-minus-label">-until+</InputLabel>
          <Select
            labelId="plus-minus-label"
            id="plus-minus-select"
            value={rule.plusMinusRange === undefined ? "" : rule.plusMinusRange}
            onChange={handleSelectChange}
            label="-until+"
            renderValue={renderValue}
          >
            {exact_wdl
              ? // Options for Won/Draw/Lost
              [
                <MenuItem key="same" value="Same">
                  Same
                </MenuItem>,
                <MenuItem key="not-same" value="Not Same">
                  Not Same
                </MenuItem>,
              ]
              : // Regular options
              [
                <MenuItem key="exact" value={0}>
                  0 (Exact)
                </MenuItem>,
                ...[1, 2, 3, 4, 5, 6].map((num) => (
                  <MenuItem key={num} value={num}>
                    {`-${num} - ${num}`}
                  </MenuItem>
                )),
              ]}
          </Select>
        </FormControl>
      </>
    );
  };

  const renderOverOptions = (stats_type) => {
    let options = [];

    if (stats_type?.includes("Goal Scored Over Row")) {
      options = [
        { value: "0.5", label: "Goal Scored Over 0.5" },
        { value: "1.5", label: "Goal Scored Over 1.5" },
      ];
    } else if (stats_type?.includes("Goal Conceeded Over Row")) {
      options = [
        { value: "0.5", label: "Goal Conceeded Over 0.5" },
        { value: "1.5", label: "Goal Conceeded Over 1.5" },
      ];
    } else if (stats_type?.includes("Total Over Row")) {
      options = [
        { value: "0.5", label: "Total Over 0.5" },
        { value: "1.5", label: "Total Over 1.5" },
        { value: "2.5", label: "Total Over 2.5" },
        { value: "3.5", label: "Total Over 3.5" },
        { value: "4.5", label: "Total Over 4.5" },
        { value: "5.5", label: "Total Over 5.5" },
        { value: "6.5", label: "Total Over 6.5" },
        { value: "7.5", label: "Total Over 7.5" },
      ];
    } else if (stats_type?.includes("Total BTTS Row")) {
      options = [
        { value: "BTTS Yes", label: "BTTS Yes" },
        { value: "BTTS No", label: "BTTS No" },
      ];
    }

    if (options.length === 0) return null;

    return (
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>
          {stats_type?.includes("Total BTTS Row")
            ? "BTTS Option"
            : "Over Value"}
        </InputLabel>
        <Select
          value={rule.overValue || ""}
          onChange={(e) => handleUpdate({ overValue: e.target.value })}
          label={
            stats_type?.includes("Total BTTS Row")
              ? "BTTS Option"
              : "Over Value"
          }
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const renderNormalComparisonFields = () => {
    const baseType = rule.stats_type?.replace("_PM", "");

    // Handle Won/Draw/Lost for exact matches
    if (baseType === "Won/Draw/Lost") {
      return renderWDLDropdown_with_single_value();
    }

    // Handle Won/Draw/Lost Row for last matches
    if (baseType === "Won/Draw/Lost Row") {
      return (
        <>
          {renderWDLDropdown()}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <FormControl>
              <InputLabel>Comparison</InputLabel>
              <Select
                value={rule.comparison_type || "equal"}
                onChange={(e) => handleUpdate({ comparison_type: e.target.value })
                }
              >
                <MenuItem value="equal">Equal</MenuItem>
                <MenuItem value="from">From</MenuItem>
              </Select>
            </FormControl>
            <TextField
              type="number"
              label="Value"
              value={rule.valueFrom}
              onChange={(e) => handleUpdate({ valueFrom: e.target.value })}
            />
            {rule.comparison_type === "from" && (
              <>
                <Typography>until</Typography>
                <TextField
                  type="number"
                  label="Until Value"
                  value={rule.valueTo}
                  onChange={(e) => handleUpdate({ valueTo: e.target.value })}
                />
              </>
            )}
          </Box>
        </>
      );
    }

    const range = getRangeOptions(baseType);
    const showOverOptions = [
      "Total Over Row",
      "Goal Scored Over Row",
      "Goal Conceeded Over Row",
      "Total BTTS Row",
    ].some((type) => baseType?.includes(type));

    return (
      <>
        {showOverOptions && renderOverOptions(baseType)}
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <FormControl>
            <InputLabel>Comparison</InputLabel>
            <Select
              value={rule.comparison_type || "equal"}
              onChange={(e) =>
                handleUpdate({ comparison_type: e.target.value })
              }
            >
              <MenuItem value="equal">Equal</MenuItem>
              <MenuItem value="from">From</MenuItem>
            </Select>
          </FormControl>
          <TextField
            type="number"
            label="Value"
            inputProps={{
              min: range.min,
              max: range.max,
              step: range.step,
            }}
            value={rule.valueFrom}
            onChange={(e) => {
              const validatedValue = validateRange(e.target.value, range);
              handleUpdate({ valueFrom: validatedValue });
            }}
          />
          {rule.comparison_type === "from" && (
            <>
              <Typography>until</Typography>
              <TextField
                type="number"
                label="Until Value"
                inputProps={{
                  min: range.min,
                  max: range.max,
                  step: range.step,
                }}
                value={rule.valueTo}
                onChange={(e) => {
                  const validatedValue = validateRange(e.target.value, range);
                  handleUpdate({ valueTo: validatedValue });
                }}
              />
            </>
          )}
        </Box>
      </>
    );
  };

  function getOrdinal(num) {
    const suffixes = ["th", "st", "nd", "rd"];
    const value = num % 100; // Get the last two digits

    return (
      num + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0])
    );
  }

  return (
    <Box
      sx={{
        border: "1.7px solid #afadad",
        borderRadius: "8px",
        padding: "20px",
        mb: 4,
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
          borderBottom: "1px solid #e0e0e0",
          pb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold", mr: 2 }}>
            Rule {index + 1}
          </Typography>
          {/* Add any other left-aligned content here */}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          {duplicateRule && (
            <IconButton onClick={() => handleDuplicateRule(index)} color="done" sx={{ mr: 1 }}>
              <ContentCopyIcon />
            </IconButton>
          )}

          <IconButton onClick={() => removeRule(index)} color="error">
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ mb: 4, fontWeight: "bold" }}>
        <FormControl required error={hasError("matchType")} fullWidth>
          <FormLabel>Select Match Type *</FormLabel>
          <RadioGroup
            row
            value={rule.matchType || ""}
            onChange={(e) => handleUpdate({ matchType: e.target.value })}
          >
            <FormControlLabel
              value="exact"
              control={<Radio />}
              label="Exact Match"
            />
            <FormControlLabel
              value="last"
              control={<Radio />}
              label="Last Matches"
            />
            <FormControlLabel
              value="team_filter"
              control={<Radio />}
              label="Team filter"
            />
          </RadioGroup>
          {getErrorMessage("matchType") && (
            <FormHelperText error>
              {getErrorMessage("matchType")}
            </FormHelperText>
          )}
        </FormControl>
      </Box>

      {rule.matchType !== "team_filter" && (
        <>
          <Box sx={{ mb: 4 }}>
            <FormControl required error={hasError("match_value")} fullWidth>
              <InputLabel>Match Selection *</InputLabel>
              <Select
                value={rule.match_value || ""}
                label="Match Selection"
                onChange={(e) => handleUpdate({ match_value: e.target.value })}
              >
                {Array.from({ length: 20 }, (_, i) => i + 1).map((num) => (
                  <MenuItem key={num} value={num}>
                    {rule.matchType === "exact"
                      ? `Last ${getOrdinal(num)} Match`
                      : `Last ${num} Match${num > 1 ? "es" : ""}`}
                  </MenuItem>
                ))}
              </Select>
              {getErrorMessage("match_value") && (
                <FormHelperText error>
                  {getErrorMessage("match_value")}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <Grid container spacing={4} sx={{ mb: 4 }}>
            <Grid item xs={12} md={6}>
              <FormControl>
                <FormLabel>Full Time</FormLabel>
                <RadioGroup row value="FT">
                  <FormControlLabel
                    value="FT"
                    control={<Radio />}
                    label="FT"
                    disabled
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl required error={hasError("team")}>
                <FormLabel>Select Team *</FormLabel>
                <RadioGroup
                  row
                  value={rule.team || ""}
                  onChange={(e) => handleUpdate({ team: e.target.value })}
                >
                  <FormControlLabel value="Home" control={<Radio />} label="Home" />
                  <FormControlLabel value="Away" control={<Radio />} label="Away" />
                  <FormControlLabel
                    value="Both Teams Combined"
                    control={<Radio />}
                    label="Both Teams Combined"
                  />
                </RadioGroup>
                {getErrorMessage("team") && (
                  <FormHelperText error>{getErrorMessage("team")}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ mb: 4 }}>
            <FormControl required error={hasError("stats_mode")}>
              <FormLabel>Statistics Mode *</FormLabel>
              <RadioGroup
                row
                value={rule.stats_mode || ""}
                onChange={(e) => handleUpdate({ stats_mode: e.target.value })}
              >
                <FormControlLabel
                  value="normal"
                  control={<Radio />}
                  label="Stats"
                />
                <FormControlLabel
                  value="plusMinus"
                  control={<Radio />}
                  label="Stats -/+"
                />
              </RadioGroup>
              {getErrorMessage("stats_mode") && (
                <FormHelperText error>
                  {getErrorMessage("stats_mode")}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          {rule.stats_mode && (
            <Box sx={{ mb: 4 }}>
              <FormControl required error={hasError("stats_type")} fullWidth>
                <InputLabel>Select Statistics Type *</InputLabel>
                <Select
                  value={rule.stats_type || ""}
                  label="Select Statistics Type"
                  onChange={(e) => handleUpdate({ stats_type: e.target.value })}
                >
                  {getStatsOptions().map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {getErrorMessage("stats_type") && (
                  <FormHelperText error>
                    {getErrorMessage("stats_type")}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          )}
          {rule.stats_type && (
            <Box sx={{ mt: 4 }}>
              {rule.stats_mode === "plusMinus" ? (
                <>
                  {renderPlusMinusFields()}
                  {(getErrorMessage("plusMinusRange") ||
                    getErrorMessage("overValue")) && (
                      <FormHelperText error>
                        {getErrorMessage("plusMinusRange") ||
                          getErrorMessage("overValue")}
                      </FormHelperText>
                    )}
                </>
              ) : (
                <>
                  {renderNormalComparisonFields()}
                  {(getErrorMessage("valueFrom") ||
                    getErrorMessage("valueTo") ||
                    getErrorMessage("comparison_type") ||
                    getErrorMessage("wdlValue")) && (
                      <FormHelperText error>
                        {getErrorMessage("valueFrom") ||
                          getErrorMessage("valueTo") ||
                          getErrorMessage("comparison_type") ||
                          getErrorMessage("wdlValue")}
                      </FormHelperText>
                    )}
                </>
              )}
            </Box>
          )}
        </>
      )}
      {rule.matchType === "team_filter" && (
        <Box sx={{ mb: 4 }}>
          <FormControl required error={hasError("team_filter")}>
            <FormControl required error={hasError("team_id_type")} fullWidth>
              <Box sx={{ mb: 4 }}>
                <InputLabel>Team ID</InputLabel>
                <Select
                  value={rule.team_id_type || ""}
                  label="Team ID"
                  onChange={(e) => handleUpdate({ team_id_type: e.target.value })}
                  sx={{ minWidth: 120 }}
                >
                  <MenuItem key={'home_team_id'} value={'home_team_id'} >
                    Home Team ID
                  </MenuItem>
                  <MenuItem key={'away_team_id'} value={'away_team_id'}>
                    Away Team ID
                  </MenuItem>
                </Select>
              </Box>
              <FormControl sx={{ minWidth: 120 }} size="medium">
                <InputLabel id="plus-minus-label">-until+</InputLabel>
                <Select
                  labelId="plus-minus-label"
                  id="plus-minus-select"
                  value={rule.team_id_comparison}
                  onChange={(e) => handleUpdate({ team_id_comparison: e.target.value })}
                  label="-until+"
                >
                  <MenuItem key="same" value="Same">
                    Same
                  </MenuItem>
                  <MenuItem key="not-same" value="Not Same">
                    Not Same
                  </MenuItem>
                </Select>
              </FormControl>
              {getErrorMessage("match_value") && (
                <FormHelperText error>
                  {getErrorMessage("match_value")}
                </FormHelperText>
              )}
            </FormControl>

          </FormControl>
        </Box>
      )}

      {errors && errors[index] && (
        <Box sx={{ mt: 2, color: "error.main" }}>
          {Object.values(errors[index]).map((error, i) => (
            <Typography key={i} variant="caption" color="error">
              {error}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Rule;
