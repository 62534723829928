import instance from "../Auth/basicConfig";

const teamData = async (searchData, apiEndpoint, matchType) => {
  return callApi(async () => { return await instance.post(apiEndpoint + '?page=' + searchData.page + '&type=' + matchType, searchData) });
};

const teamDataBacktest = async (searchData, apiEndpoint, matchType, formula_id) => {
  return callApi(async () => { return await instance.post(apiEndpoint + '?page=' + searchData.page + '&type=' + matchType + '&formula_id=' + formula_id, searchData) });
};

const NotificationTable = async ( apiEndpoint,fixture_id) => {
  console.log("fixture_id",fixture_id," inNototifcationTable",apiEndpoint);
  return callApi(async () => { return await instance.get(apiEndpoint + '?fixture_id=' + fixture_id)});
};



const teamHeader = async () => {
  return callApi(async () => { return await instance.get('/team_header') });
};

const download_team_data = async (payload, apiEndPoint) => {
  return callApi(async () => { return await instance.post(apiEndPoint, payload, { responseType: 'arraybuffer' }) });
};

const getSearchSuggestions = async (searchInp) => {
  return callApi(async () => { return await instance.get('/team_suggest?query=' + searchInp) });
};

const getMatchingTeamData = async ({ main_fixture_ids, formula_id,page,limit }) => {
  console.log("main_fixture_ids",main_fixture_ids,"formula_id", formula_id);
  return callApi(async () => { return await instance.get('/get_matching_team_data', { params: { main_fixture_ids, formula_id, page, limit } }) });
};
const getMatchingTeamDataNotification = async (main_fixture_ids, page, limit) => {
  console.log("main_fixture_ids",main_fixture_ids);
  return callApi(async () => { return await instance.get('/get_matching_team_data' + '?formula_id=from_notification' + '&main_fixture_ids=' + main_fixture_ids + '&page='+ page + '&limit='+limit) });
};

async function callApi(fun) {
  try {
    let response = await fun();
    if (response.name === "AxiosError")
      response = response.response;
    if (response.status === 422 || response.status === 401) {
      return { authFailed: true, data: null, message: "" };
    } else if (response.status === 200) {
      return { authFailed: false, data: response.data, message: "success", response: response };
    }
    return { authFailed: false, data: null, message: "API failed with status: " + response.status };
  } catch (error) {
    return { authFailed: false, data: null, message: "API failed with error: " + error };
  }
}

export default { teamData, teamHeader, download_team_data, getSearchSuggestions, getMatchingTeamData,teamDataBacktest,NotificationTable, getMatchingTeamDataNotification};
