import React , { useState} from 'react'
import Button from '@mui/material/Button';
function FilterPopup({ filterObj, filters, onApply,onReset, filterVisible}) {
    const column = filterObj.key;
    const filterLbl = filterObj.label;
    let initialValue = filters[column];
    const [inputValue, setInputValue] = useState(initialValue?initialValue:'');
    
  const handleApplyFilter = async () => {
    await onApply(column, inputValue)
    await filterVisible()
  };

  const handleResetFilter = async () => {
    setInputValue('');
    await onReset(column, '')
    await filterVisible()
  };

  return (
    <div style={{ position: 'absolute', backgroundColor: 'white', border: '1px solid black', padding: '10px', left: filterObj.left, top: filterObj.top }}>
       <div><i className="fa fa-close" style={{fontSize:'24px', float:'right', color:'black'}} onClick={() => filterVisible()}></i></div>
       <div style={{marginTop:'6%'}}>
       <label htmlFor="searchBox"><h4> {filterLbl} &nbsp;</h4></label><input
        type="text" id="searchBox"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      /> <br />
      <div style={{marginTop:'5%'}}>
      <Button variant="contained"  color="secondary" size="large" onClick={handleApplyFilter}>Apply</Button>  &nbsp;  &nbsp;
      <Button variant="contained" color="success" size="large" onClick={handleResetFilter}>Reset</Button>

       </div>
      </div>
    </div>
  );
  
}

export default FilterPopup