import React, { useState, useEffect } from "react";
import Chip from '@mui/material/Chip';
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Button,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
  Checkbox,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  ListItemText,
  Snackbar,
  Alert,
} from "@mui/material";
import { Plus } from "lucide-react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { styled } from "@mui/system";
import axios from "axios";
import { CirclePlus } from "lucide-react";
import Header from "../Components/Header";
import Rule from "../Components/Rule";
import { ApiUrl } from "../Auth/basicConfig";
import SearchableSelectOne from "../Components/SearchableSelectOne";
import SearchableSelectMultiple from "../Components/SearchableSelectMultiple";

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0.5),
  textTransform: "none",
  fontSize: "1rem", // Increased font size
  padding: theme.spacing(1, 2),
}));

const GreenButton = styled(StyledButton)({
  backgroundColor: "#4caf50",
  color: "white",
  "&:hover": {
    backgroundColor: "#45a049",
  },
});

const FormulaForm = () => {

  const navigate = useNavigate();

  const { formulaId } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [isCountryLoading, setIsCountryLoading] = useState(true);
  const [isLeaguesLoading, setIsLeaguesLoading] = useState(true);
  const [isFormDataLoading, setIsFormDataLoading] = useState(true);

  const [rules, setRules] = useState([{ id: Date.now() }]);
  const [formulaName, setFormulaName] = useState("");
  const [receiveAlerts, setReceiveAlerts] = useState(true);
  const [prematchAlert, setPrematchAlert] = useState(2160);
  const [baseAllMatches, setBaseAllMatches] = useState(true);
  const [applyLimitsToResults, setApplyLimitsToResults] = useState(false);
  const [limitResultsTo, setLimitResultsTo] = useState(25);
  const [calculateTotalCount, setCalculateTotalCount] = useState(false);
  const [countries, setCountries] = useState([]);

  const [selectedCountryIds, setSelectedCountryIds] = useState([]);
  const [excludedCountryIds, setExcludedCountryIds] = useState([]);
  const [selectedLeagueIds, setSelectedLeagueIds] = useState([]);
  const [excludedLeagueIds, setExcludedLeagueIds] = useState([]);

  const [allLeagues, setAllLeagues] = useState([]);
  const [allLeaguesMapping, setAllLeaguesMapping] = useState({});
  const [allLeaguesToSelect, setAllLeaguesToSelect] = useState([]);
  const [allLeaguesToExclude, setAllLeaguesToExclude] = useState([]);

  // Fetch Countries and Leagues
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          `${ApiUrl.baseURL}/fetch_countries`,
          {
            headers: {
              "ngrok-skip-browser-warning": "true",
            },
          }
        );
        setCountries(response.data);

      } catch (error) {
        console.error("Error fetching countries:", error);
      } finally {
        setIsCountryLoading(false);
      }
    };
    const fetchLeagues = async () => {

      try {
        const response = await axios.get(`${ApiUrl.baseURL}/fetch_leagues?country_ids=all`, {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        });
        const allLeagues = response.data;
        setAllLeagues(allLeagues);
        const leaguesMapping = {};
        allLeagues.forEach((league) => {
          leaguesMapping[league.leagueId] = league.name;
        })
        setAllLeaguesMapping(leaguesMapping);
      } catch (error) {
        console.error("Error fetching leagues:", error);
      } finally {
        setIsLeaguesLoading(false);
      }
    };
    const fetchFormulaData = async () => {
      try {
        if (!formulaId) return;
        const response = await axios.get(
          `${ApiUrl.baseURL}/edit_formula/get?formula_id=${formulaId}`
        );
        const formulaData = response.data;

        // Transform the API rules to match component format
        const transformedRules = formulaData.matchRules.map((rule) => ({
          id: Date.now() + Math.random(),
          matchType: rule.team_filter ? "team_filter" : (rule.exact_match ? "exact" : "last"),
          match_value: rule.match_value?.toString(),
          team: rule.team_type,
          stats_type: rule.stats_type,
          stats_mode: rule.stats_mode || "normal",
          comparison_type: rule.comparison_type || "equal",
          team_id_type: rule.team_id_type,
          team_id_comparison: rule.comparison_value,

          // Use valueFrom to hold the single comparison_value when comparison_type is "equal"
          valueFrom:
            rule.comparison_type === "equal"
              ? rule.comparison_value?.toString()
              : rule.comparison_value?.min?.toString(),

          // Only include valueTo if comparison_type is not "equal"
          valueTo:
            rule.comparison_type !== "equal"
              ? rule.comparison_value?.max?.toString()
              : undefined,

          plusMinusRange:
            rule.stats_mode === "plusMinus" ? rule.comparison_value : "0",
          wdlValue:
            rule.stats_value !== undefined
              ? rule.stats_value
              : rule.comparison_value,
          overValue: rule.stats_value,
          wdl_value: rule.comparison_value,
          stats_value: rule.stats_value,
        }));

        setFormulaName(formulaData.name);
        setReceiveAlerts(formulaData.receiveAlerts);
        setBaseAllMatches(formulaData.baseAllMatches);
        setSelectedLeagueIds(formulaData.league || []);
        setExcludedLeagueIds(formulaData.excluded_leagues || []);
        setPrematchAlert(formulaData.prematchAlertInMinutes?.toString());
        setApplyLimitsToResults(formulaData.apply_limits_to_results);
        setCalculateTotalCount(formulaData.calculate_total_count);
        setLimitResultsTo(formulaData.limit_results_to);
        setRules(transformedRules);
      } catch (error) {
        console.error("Error fetching formula data:", error);
        setAlertMessage("Error loading formula data");
        setAlertSeverity("error");
        setShowAlert(true);
      } finally {
        setIsFormDataLoading(false);
      }
    };
    fetchCountries();
    fetchLeagues();
    fetchFormulaData();
  }, []);


  useEffect(() => {
    if (selectedCountryIds.length > 0) {
      setAllLeaguesToSelect(allLeagues.filter(league => selectedCountryIds.includes(league.leagueCountryId)));
    } else {
      setAllLeaguesToSelect([]);
    }
  }, [selectedCountryIds]);


  useEffect(() => {
    if (excludedCountryIds.length > 0) {
      setAllLeaguesToExclude(allLeagues.filter(league => excludedCountryIds.includes(league.leagueCountryId)));
    } else {
      setAllLeaguesToExclude([]);
    }
  }, [excludedCountryIds]);

  const addRule = () => {
    setRules([
      ...rules,
      {
        id: Date.now(),
        match_type: "",
        match_value: "",
        team: "",
        stats_mode: "",
        stats_type: "",
        comparison_type: "",
        valueFrom: "",
        valueTo: "",
        plusMinusRange: "",
      },
    ]);
  };

  const duplicateRule = (index) => {
    if (index !== undefined && index >= 0) {
      setRules((prevRules) => {
        const ruleToDuplicate = prevRules[index];
        return [
          ...prevRules,
          {
            ...ruleToDuplicate,
            id: Date.now(), // Ensure unique id
          },
        ];
      });
    }
  };

  const removeRule = (index) => {
    setRules((prevRules) => prevRules.filter((_, i) => i !== index));
  };

  const formatValidationErrors = (errors) => {
    // Create an object to group errors by rule number
    let groupedErrors = {};

    // Loop through nested rule errors
    if (errors.rules) {
      for (const ruleIndex in errors.rules) {
        const ruleErrors = errors.rules[ruleIndex];
        const updatedRuleIndex = parseInt(ruleIndex) + 1;

        // Initialize array for this rule if it doesn't exist
        if (!groupedErrors[updatedRuleIndex]) {
          groupedErrors[updatedRuleIndex] = [];
        }

        // Add all errors for this rule to its group
        for (const field in ruleErrors) {
          // Extract just the error message without "Rule X:" prefix
          const errorMessage = ruleErrors[field].replace(/^Rule \d+: /, "");
          groupedErrors[updatedRuleIndex].push(errorMessage);
        }
      }
    }

    // Convert grouped errors to formatted strings
    let messages = [];
    for (const ruleNum in groupedErrors) {
      // Add rule header
      messages.push(`Rule ${ruleNum}:`);
      // Add indented error messages for this rule
      groupedErrors[ruleNum].forEach((error) => {
        messages.push(`   • ${error}`);
      });
      // Add a blank line between rules
      messages.push("");
    }

    return messages.join("\n");
  };

  const handleSubmit = () => {
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);

      // Use the formatting function to generate the detailed error message
      const detailedErrorMessage = formatValidationErrors(validationErrors);

      setAlertSeverity("error");
      setAlertMessage(
        `Please fix the following validation errors:\n${detailedErrorMessage}`
      );
      setShowAlert(true);
      return;
    }

    console.log("Rules", rules);
    setIsSaving(true);
    const formData = {
      name: formulaName,
      receiveAlerts,
      baseAllMatches,
      league: selectedLeagueIds,
      excluded_leagues: excludedLeagueIds,
      apply_limits_to_results: applyLimitsToResults,
      ...(applyLimitsToResults && { limit_results_to: limitResultsTo }),
      ...(applyLimitsToResults && {
        calculate_total_count: calculateTotalCount,
      }),
      matchRules: rules.map((rule) => {
        // Specific handling for different stats
        if (rule.matchType === "team_filter") {
          return {
            team_filter: true,
            team_id_type: rule.team_id_type,
            comparison_value: rule.team_id_comparison,
            stats_mode: "plusMinus",
            stats_type: rule.team_id_type === "home_team_id" ? "Home Team ID" : "Away Team ID",

          }
        }

        if (
          rule.stats_type === "Won/Draw/Lost" &&
          rule.matchType === "exact" &&
          rule.stats_mode !== "plusMinus"
        ) {
          return {
            exact_match: rule.matchType === "exact",
            last_match: rule.matchType === "last",
            team_filter: rule.matchType === "team_filter",
            match_value: parseInt(rule.match_value),
            stats_type: rule.stats_type,
            team_type: rule.team,
            comparison_value: rule.wdlValue
          };
        }

        if (
          rule.stats_type === "Won/Draw/Lost" &&
          rule.stats_mode !== "plusMinus"
        ) {
          console.log("Hello");
          return {
            exact_match: rule.matchType === "exact",
            last_match: rule.matchType === "last",
            team_filter: rule.matchType === "team_filter",
            match_value: parseInt(rule.match_value),
            stats_type: rule.stats_type,
            team_type: rule.team,
            stats_value: rule.wdlValue,
          };
        }

        if (
          rule.stats_type === "Won/Draw/Lost Row" &&
          rule.stats_mode !== "plusMinus"
        ) {
          console.log("rule.stats_mode", rule.stats_mode);
          const baseRule_wdlr = {
            exact_match: rule.matchType === "exact",
            last_match: rule.matchType === "last",
            team_filter: rule.matchType === "team_filter",
            match_value: parseInt(rule.match_value || "0"),
            stats_type: rule.stats_type,
            team_type: rule.team,
            stats_value: rule.wdlValue,
            comparison_type: rule.comparison_type,
          };
          if (rule.comparison_type === "equal") {
            baseRule_wdlr.comparison_value = parseInt(rule.valueFrom) || 0;
          } else {
            baseRule_wdlr.comparison_value = {
              min: parseInt(rule.valueFrom) || 0,
              max: parseInt(rule.valueTo) || parseInt(rule.valueFrom) || 0,
            };
          }
          return baseRule_wdlr;
        }

        if (
          rule.stats_type === "Won/Draw/Lost Row" &&
          rule.stats_mode === "plusMinus"
        ) {
          console.log("rule.stats_mode", rule.stats_mode);
          const baseRule_wdlr = {
            exact_match: rule.matchType === "exact",
            last_match: rule.matchType === "last",
            team_filter: rule.matchType === "team_filter",
            match_value: parseInt(rule.match_value || "0"),
            stats_type: rule.stats_type,
            team_type: rule.team,
            stats_value: rule.wdlValue,
            comparison_value: rule.plusMinusRange,
            stats_mode: "plusMinus",
          };
          return baseRule_wdlr;
        }

        if (
          rule.stats_type === "Won/Draw/Lost" &&
          rule.stats_mode === "plusMinus"
        ) {
          console.log("Hello plusMinus");
          console.log("rule.stats_mode", rule.stats_mode);
          console.log("rule.plsuminusRange", rule.plusMinusRange);

          return {
            exact_match: rule.matchType === "exact",
            last_match: rule.matchType === "last",
            team_filter: rule.matchType === "team_filter",
            match_value: parseInt(rule.match_value),
            stats_type: rule.stats_type,
            team_type: rule.team,
            comparison_value: rule.plusMinusRange,
            stats_mode: "plusMinus",
          };
        }

        if (
          [
            "Goal Scored Over Row",
            "Goal Conceeded Over Row",
            "Total Over Row",
            "Total BTTS Row",
          ].includes(rule.stats_type) &&
          rule.stats_mode === "plusMinus"
        ) {
          const baseRule_over = {
            exact_match: rule.matchType === "exact",
            last_match: rule.matchType === "last",
            team_filter: rule.matchType === "team_filter",
            match_value: parseInt(rule.match_value || "0"),
            stats_type: rule.stats_type,
            team_type: rule.team,
            stats_value: rule.overValue,
            comparison_value: rule.plusMinusRange,
            stats_mode: "plusMinus",
          };

          return baseRule_over;
        }

        if (
          [
            "Goal Scored Over Row",
            "Goal Conceeded Over Row",
            "Total Over Row",
            "Total BTTS Row",
          ].includes(rule.stats_type)
        ) {
          console.log("Hello over");
          const baseRule_over = {
            exact_match: rule.matchType === "exact",
            last_match: rule.matchType === "last",
            team_filter: rule.matchType === "team_filter",
            match_value: parseInt(rule.match_value || "0"),
            stats_type: rule.stats_type,
            team_type: rule.team,
            stats_value: rule.overValue,
            comparison_type: rule.comparison_type,
          };

          if (rule.comparison_type === "equal") {
            baseRule_over.comparison_value = parseInt(rule.valueFrom) || 0;
          } else {
            baseRule_over.comparison_value = {
              min: parseInt(rule.valueFrom) || 0,
              max: parseInt(rule.valueTo) || parseInt(rule.valueFrom) || 0,
            };
          }
          return baseRule_over;
        }

        if (rule.stats_mode && rule.stats_mode === "plusMinus") {
          const baseRule_sts_plus_minus = {
            exact_match: rule.matchType === "exact",
            last_match: rule.matchType === "last",
            team_filter: rule.matchType === "team_filter",
            match_value: parseInt(rule.match_value || "0"),
            stats_type: rule.stats_type,
            team_type: rule.team,
            stats_mode: "plusMinus",
            comparison_value: rule.plusMinusRange,
          };
          console.log("Here v3");
          return baseRule_sts_plus_minus;
        }

        const baseRule = {
          exact_match: rule.matchType === "exact",
          last_match: rule.matchType === "last",
          team_filter: rule.matchType === "team_filter",
          match_value: parseInt(rule.match_value || "0"),
          stats_type: rule.stats_type,
          stats_value: rule.stats_value,
          team_type: rule.team,
          comparison_type: rule.comparison_type,
        };

        if (rule.comparison_type === "equal") {
          baseRule.comparison_value = parseInt(rule.valueFrom) || 0;
        } else {
          baseRule.comparison_value = {
            min: parseInt(rule.valueFrom) || 0,
            max: parseInt(rule.valueTo) || parseInt(rule.valueFrom) || 0,
          };
        }

        return baseRule;
      }),
    };

    if (receiveAlerts) {
      formData.prematchAlertInMinutes = parseInt(prematchAlert);
    }

    let params = {};
    if (formulaId !== undefined) {
      params = {
        params: { formula_id: formulaId },
        headers: {
          "Content-Type": "application/json",
        },
      }
    }
    const apiUrl = formulaId === undefined ? `${ApiUrl.baseURL}/add_new_formula` : `${ApiUrl.baseURL}/edit_formula`;
    const callMethod = formulaId === undefined ? axios.post : axios.put;
    callMethod(apiUrl, formData, params)
      .then((response) => {
        // Handle success response
        console.log("Formula added successfully:", response.data);
        setAlertMessage("Formula added successfully!");
        setAlertSeverity("success");
        setShowAlert(true);
        navigate("/formulaList");
      })
      .catch((error) => {
        console.error("There was an error adding the formula:", error);
        if (error.response && error.response.status === 400) {
          const errorMessage =
            error.response.data.error ||
            "There was an error adding the formula";
          setAlertMessage(errorMessage);
        } else {
          setAlertMessage("There was an error adding the formula");
        }
        setAlertSeverity("error");
        setShowAlert(true);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const updateRule = (index, newRule) => {
    setRules((prevRules) =>
      prevRules.map((rule, i) => (i === index ? { ...rule, ...newRule } : rule))
    );
  };

  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("error");
  const validateForm = () => {
    const newErrors = {};

    // Formula name validation
    if (!formulaName.trim()) {
      newErrors.formulaName = "Formula name is required";
    }

    // Prematch alert validation when alerts are enabled
    if (receiveAlerts && !prematchAlert) {
      newErrors.prematchAlert = "Please select a prematch alert time";
    }

    // Rules validation
    const ruleErrors = {};
    rules.forEach((rule, index) => {
      const currentRuleErrors = {};

      if (!rule.matchType) {
        currentRuleErrors.matchType = "Match type selection is required";
      }

      if (rule.matchType === "team_filter") {
        console.log(rule);
        if (!rule.team_id_type)
          currentRuleErrors.team_id_type = "Team ID Type selection is required";
        if (!rule.team_id_comparison)
          currentRuleErrors.plusMinusRange = "Until -/+ selection is required";
      } else {
        if (!rule.match_value) {
          currentRuleErrors.match_value = "Match selection is required";
        }

        if (!rule.team) {
          currentRuleErrors.team = "Team selection is required";
        }

        if (!rule.stats_type) {
          currentRuleErrors.stats_type = "Statistics type is required";
        }
      }
      if (Object.keys(currentRuleErrors).length > 0) {
        ruleErrors[index] = currentRuleErrors;
      }
    });

    if (Object.keys(ruleErrors).length > 0) {
      newErrors.rules = ruleErrors;
    }
    console.log("ruleErrors", newErrors);
    return newErrors;
  };

  const setSelectionChange = (setIdsCollection, itemId, added, isLeagueIds) => {
    if (added) {
      setIdsCollection((prev) => [...prev, itemId]);
    } else {
      setIdsCollection((prev) => prev.filter((id) => id !== itemId));
    }
    if (isLeagueIds) {
      setIdsCollection(
        (prev) => prev.sort((x, y) => allLeaguesMapping[x].localeCompare(allLeaguesMapping[y]))
      )
    }
  };

  if (isSaving || isCountryLoading || isLeaguesLoading || isFormDataLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: "#f0f0f0", minHeight: "100vh" }}>
      <Header />
      <Box sx={{ padding: "20px" }}>
        <Box sx={{ marginBottom: "20px" }}>
          <StyledButton
            variant="outlined"
            onClick={() => navigate("/formulaList")}
          >
            Formulas
          </StyledButton>
          <StyledButton variant="outlined">Backtest Spreadsheet</StyledButton>
          <StyledButton variant="outlined">Spreadsheet</StyledButton>
          <GreenButton
            variant="contained"
            onClick={() => alert("You are already on Add New Formula Page")}
          >
            {formulaId === undefined ? "Add New Formula" : "Edit Formula"}
          </GreenButton>
        </Box>

        <Box
          component="form"
          sx={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <TextField
              label="Formula Name"
              variant="outlined"
              fullWidth
              sx={{ mr: 2 }}
              value={formulaName}
              onChange={(e) => setFormulaName(e.target.value)}
              error={!!errors.formulaName} // Add this
              helperText={errors.formulaName}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={receiveAlerts}
                  onChange={(e) => setReceiveAlerts(e.target.checked)}
                />
              }
              label="Receive Alerts"
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <SearchableSelectMultiple
              label="Country"
              value={selectedCountryIds}
              onSelectionChange={(itemId, flag) => setSelectionChange(setSelectedCountryIds, itemId, flag, false)}
              items={countries}
              defaultValue="0"
              defaultLabel="None"
            />

            <Box sx={{ width: "16px" }} />

            <SearchableSelectMultiple
              label="Leagues"
              value={selectedLeagueIds}
              onSelectionChange={(itemId, flag) => setSelectionChange(setSelectedLeagueIds, itemId, flag, true)}
              items={allLeaguesToSelect}
              defaultValue="0"
              defaultLabel="None"
            />
          </Box>

          <Box
            sx={{
              display: "block",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}>
            &nbsp; <span style={{ fontSize: 'medium', marginBottom: '10px' }}>Selected Leagues: </span>
            {selectedLeagueIds.map((itemId) => (
              <Chip
                key={itemId}
                label={allLeaguesMapping[itemId]}
                size="small" color="secondary"
                onDelete={() => setSelectionChange(setSelectedLeagueIds, itemId, false, false)}
                style={{ marginRight: '5px', marginBottom: '5px', fontWeight: '900', fontSize: 'small', marginLeft: '8px' }}
              />
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <SearchableSelectMultiple
              label="Country to be excluded"
              value={excludedCountryIds}
              onSelectionChange={(itemId, flag) => setSelectionChange(setExcludedCountryIds, itemId, flag, false)}
              items={countries}
              defaultValue="0"
              defaultLabel="None"
            />
            <Box sx={{ width: "16px" }} />
            <SearchableSelectMultiple
              label="Leagues to be excluded"
              value={excludedLeagueIds}
              onSelectionChange={(itemId, flag) => setSelectionChange(setExcludedLeagueIds, itemId, flag, true)}
              items={allLeaguesToExclude}
              defaultValue="0"
              defaultLabel="None"
            />
          </Box>

          <Box
            sx={{
              display: "block",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}>
            &nbsp; <span style={{ fontSize: 'medium', marginBottom: '10px' }}>Excluded Leagues: </span>
            {excludedLeagueIds.map((itemId) => (
              <Chip
                key={itemId}
                label={allLeaguesMapping[itemId]}
                size="small" color="secondary"
                onDelete={() => setSelectionChange(setExcludedLeagueIds, itemId, false, false)}
                style={{ marginRight: '5px', marginBottom: '5px', fontWeight: '900', fontSize: 'small', marginLeft: '8px' }}
              />
            ))}
          </Box>

          {/* Base Matches Checkbox */}
          <Typography variant="subtitle1" sx={{ marginBottom: "10px" }}>
            Base
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={baseAllMatches}
                disabled
              />
            }
            label="All Matches Base"
            sx={{ marginBottom: "20px" }}
          />

          {/* Limit Results Option */}
          <Box sx={{ mb: 3 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={applyLimitsToResults}
                  onChange={(e) => setApplyLimitsToResults(e.target.checked)}
                />
              }
              label="Limit Results"
            />

            {applyLimitsToResults && (
              <>
                <Box
                  sx={{ display: "flex", alignItems: "center", mt: 2, gap: 2 }}
                >
                  <TextField
                    type="text" // Change type to "text"
                    label="Limit Results Count"
                    variant="outlined"
                    value={limitResultsTo}
                    onChange={(e) => {
                      const value = e.target.value.replace(/^0+/, ""); // Remove leading zeros
                      setLimitResultsTo(
                        value === "" ? "" : Math.min(Number(value), 50000)
                      ); // Ensure within range
                    }}
                    inputProps={{
                      inputMode: "numeric", // Ensure numeric keypad on mobile devices
                      pattern: "[0-9]*", // Restrict to numeric characters
                    }}
                    sx={{ width: "200px" }}
                    error={!!errors.limitResultsTo} // Display error if validation fails
                    helperText={
                      errors.limitResultsTo ||
                      "Enter a number between 1 and 50,000"
                    }
                  />
                </Box>
                <Box sx={{ mb: 3 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={calculateTotalCount}
                        onChange={(e) =>
                          setCalculateTotalCount(e.target.checked)
                        }
                      />
                    }
                    label="Calculate Total Count"
                  />
                </Box>
              </>
            )}
          </Box>

          {receiveAlerts && (
            <Box sx={{ mb: 3 }}>
              <FormControl fullWidth error={!!errors.prematchAlert} required>
                <InputLabel>PreMatch Alert before Kickoff</InputLabel>
                <Select
                  value={prematchAlert}
                  onChange={(e) => setPrematchAlert(e.target.value)}
                  label="Pre-match Alert"
                >
                  <MenuItem value={30}>30 minutes before KO</MenuItem>
                  <MenuItem value={60}>1 hour before KO</MenuItem>
                  <MenuItem value={720}>12 hours before KO</MenuItem>
                  <MenuItem value={1440}>24 hours before KO</MenuItem>
                  <MenuItem value={2160}>36 hours before KO</MenuItem>
                  <MenuItem value={2880}>48 hours before KO</MenuItem>
                </Select>
                {errors.prematchAlert && ( // Add this
                  <FormHelperText>{errors.prematchAlert}</FormHelperText>
                )}
              </FormControl>
            </Box>
          )}

          {/* Rules Section */}

          <Box className="mt-8">
            <Typography className="text-xl font-semibold mb-4">
              Rules
            </Typography>

            {rules.map((rule, index) => (
              <Rule
                key={rule.id}
                index={index}
                rule={rule}
                updateRule={updateRule}
                removeRule={removeRule}
                duplicateRule={duplicateRule}
                errors={errors.rules?.[index]}
              />
            ))}

            <Box sx={{ display: "flex", gap: 1, marginTop: "20px" }}>
              <Button
                variant="outlined"
                onClick={addRule}
                startIcon={<CirclePlus />}
              >
                Add Rule
              </Button>
            </Box>
          </Box>

          {/* Submit Button */}
          <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              size="large"
            >
              Save Formula
            </Button>
          </Box>

          {/* Alert Snackbar */}
          <Snackbar
            open={showAlert}
            autoHideDuration={6000}
            onClose={() => setShowAlert(false)}
          >
            <Alert
              severity={alertSeverity}
              onClose={() => setShowAlert(false)}
              sx={{
                fontSize: "16px",
                maxHeight: "70vh",
                overflowY: "auto",
                "& .MuiAlert-message": {
                  fontSize: "16px",
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                },
              }}
            >
              {alertMessage}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Box>
  );
};

export default FormulaForm;
