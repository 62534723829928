import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Badge,
  Popover,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { ApiUrl } from "../Auth/basicConfig";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { formatDistanceToNow } from 'date-fns';
import NotificationSound from '../Audio/Notifcation/browser_notification.mp3';
import NotificationLogo from '../img/soccer_icon.jpeg';

const useNotificationPermission = () => {
  const [isPermissionGranted, setIsPermissionGranted] = useState(false);
  const [showPermissionDialog, setShowPermissionDialog] = useState(false);
  const [lastNotificationCount, setLastNotificationCount] = useState(0);

  useEffect(() => {
    const checkNotificationPermission = () => {
      if (!("Notification" in window)) {
        setIsPermissionGranted(false);
        setShowPermissionDialog(false);
        return;
      }

      const currentPermission = Notification.permission;
      
      localStorage.setItem('notificationPermission', currentPermission);
      
      setIsPermissionGranted(currentPermission === "granted");
      setShowPermissionDialog(currentPermission !== "granted" && currentPermission !== "denied");
    };

    checkNotificationPermission();

    window.addEventListener('permissionchange', checkNotificationPermission);

    return () => {
      window.removeEventListener('permissionchange', checkNotificationPermission);
    };
  }, []);

  const requestNotificationPermission = async () => {
    if (!("Notification" in window)) return false;

    const permission = await Notification.requestPermission();
    
    localStorage.setItem('notificationPermission', permission);
    
    setIsPermissionGranted(permission === "granted");
    setShowPermissionDialog(permission !== "granted" && permission !== "denied");

    return permission === "granted";
  };

  const createBrowserNotification = (count) => {
    console.log("Notification count in createBrowserNotification function:- ", count);

    console.log("Notification count in last notifcation function:- ", lastNotificationCount);
    if (count === 0) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        registrations.forEach(registration => {
          registration.getNotifications().then(notifications => {
            notifications.forEach(notification => notification.close());
          });
        });
      }).catch(error => {
        console.error('Error clearing notifications:', error);
      });
      return;
    }

    if (!isPermissionGranted || count <= lastNotificationCount) return;
    navigator.serviceWorker.getRegistrations().then(registrations => {
      registrations.forEach(registration => {
        registration.getNotifications().then(notifications => {
          notifications.forEach(notification => notification.close());
        });
      });

      console.log('Creating notification in browser notification function:', count);
      console.log(`You have ${count} new notification${count !== 1 ? 's' : ''}`)
      const notification = new Notification(`You have ${count} new notification${count !== 1 ? 's' : ''}`, {
        body: 'Click to view details',
        icon: NotificationLogo,
      });

      const audio = new Audio(NotificationSound);
      audio.play().catch(() => { });

      notification.onclick = () => {
        window.focus();
        notification.close();
      };

      setLastNotificationCount(count);
    }).catch(error => {
      console.error('Error managing notifications:', error);
    });
  };

  return {
    isPermissionGranted,
    showPermissionDialog,
    setShowPermissionDialog,
    requestNotificationPermission,
    createBrowserNotification
  };
};


const useNotificationCount = () => {
  const [notificationCount, setNotificationCount] = useState(0);
  const [lastDisplayedCount, setLastDisplayedCount] = useState(0);

  useEffect(() => {
    let eventSource;

    const connectToSSE = () => {
      eventSource = new EventSource(`${ApiUrl.baseURL}/notifications_count`);

      eventSource.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          
          const newCount = data.notifications_count;
          if (typeof newCount !== 'number' || newCount < 0) {
            console.warn('Invalid notification count received:', newCount);
            return;
          }
          
          // Only increment if it's a new notification
          setNotificationCount(prevCount => 
            newCount > lastDisplayedCount ? newCount : prevCount
          );
        } catch (error) {
          console.error('Error parsing SSE data:', error, 'Raw data:', event.data);
        }
      };

      eventSource.onerror = (error) => {
        console.error('SSE connection error:', error);
        eventSource.close();
        setTimeout(connectToSSE, 5000);
      };
    };

    connectToSSE();

    return () => {
      if (eventSource) {
        eventSource.close();
      }
    };
  }, [lastDisplayedCount]);

  return { 
    notificationCount, 
    setNotificationCount,
    lastDisplayedCount,
    setLastDisplayedCount 
  };
};

const MatchRow = ({ match, handleRowClick }) => {
  const notificationTime = match.notificationTime
    ? formatDistanceToNow(new Date(match.notificationTime * 1000), { addSuffix: true })
    : 'Invalid time';

  return (
    <Box
      onClick={() => handleRowClick(match.formula_id)} 
      sx={{
        width: '100%',
        bgcolor: match.index % 2 === 0 ? '#f5f5f5' : 'white',
        position: 'relative',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#e0e0e0',
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            'repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(0,0,0,0.02) 10px, rgba(0,0,0,0.02) 20px)',
          pointerEvents: 'none',
        },
      }}
    >
      <Box sx={{ p: 1 }}>
        <Typography
          variant="body2"
          sx={{
            color: '#666',
            mb: 1,
          }}
        >
          <b>{match.status}</b>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: '0.875rem',
              color: '#22c55e',
              bgcolor: '#e8f5e9',
              px: 1,
              py: 0.5,
              borderRadius: 1,
              minWidth: 'fit-content',
            }}
          >
            {match.date}{" "}-{" "}{match.time}
          </Typography>

          <Typography
            sx={{
              bgcolor: '#FF5722',
              color: 'white',
              px: 1,
              py: 0.5,
              borderRadius: 1,
              fontSize: '0.875rem',
              minWidth: 'fit-content',
            }}
          >
            {match.country}
          </Typography>

          <Typography
            sx={{
              bgcolor: '#3f51b5',
              color: 'white',
              px: 1,
              py: 0.5,
              borderRadius: 1,
              fontSize: '0.875rem',
              minWidth: 'fit-content',
            }}
          >
            {match.competition}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              flex: 1,
            }}
          >
            <Typography sx={{ fontSize: '0.875rem' }}>{match.homeTeam}</Typography>
            <Typography sx={{ fontSize: '0.875rem' }}>-</Typography>
            <Typography sx={{ fontSize: '0.875rem' }}>{match.awayTeam}</Typography>
          </Box>
          <Typography
            sx={{
              color: '#666',
              fontSize: '0.75rem',
              minWidth: 'fit-content',
              ml: 'auto',
            }}
          >
            {notificationTime}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};


const Header = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { 
    notificationCount, 
    setNotificationCount, 
    lastDisplayedCount,
    setLastDisplayedCount 
  } = useNotificationCount();
  const {
    isPermissionGranted,
    showPermissionDialog,
    setShowPermissionDialog,
    requestNotificationPermission,
    createBrowserNotification
  } = useNotificationPermission();

  useEffect(() => {
    if (notificationCount > 0) {
      console.log('Notification count:', notificationCount);
      createBrowserNotification(notificationCount);
    }
  }, [notificationCount, createBrowserNotification]);


  const fetchNotifications = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await fetch(`${ApiUrl.baseURL}/notifications`);
      if (!response.ok) {
        throw new Error('Failed to fetch notifications');
      }
      const data = await response.json();

      const transformedNotifications = (data.notifications || []).map((notification, index) => ({
        homeTeam: notification.home_team_name,
        awayTeam: notification.away_team_name,
        date: notification.date,
        country: notification.country,
        notificationTime: notification.notification_timestamp,
        competition: notification.league,
        status: notification.formula_name,
        time: notification.time,
        formula_id: notification.formula_id,
        index,
        fixtureId: notification.fixture_id,
      }));

      setNotifications(transformedNotifications);
    } catch (err) {
      setError(`Error: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRowClick = (fixtureId) => {
    const url = `/backtest-results/${fixtureId}`;
    // Open the URL in a new tab with the state appended as a query parameter
    window.open(`${url}`, "_blank");
  };

  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
    await fetchNotifications();
    
    setNotificationCount(lastDisplayedCount);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'notification-popover' : undefined;

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: "#333" }}>
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                marginRight: "20px",
                cursor: 'pointer',
              }}
              onClick={() => navigate('/home')}
            >
              BASE
            </span>
            <span
              style={{
                color: "#4caf50",
                cursor: 'pointer',
              }}
              onClick={() => navigate('/formulaList')}
            >
              FORMULAS
            </span>
          </Typography>
          <Button
            color="inherit"
            sx={{
              minWidth: 'auto',
              padding: '8px',
            }}
            onClick={handleClick}
          >
            <Badge
              badgeContent={notificationCount}
              color="error"
              sx={{
                '& .MuiBadge-badge': {
                  right: -3,
                  top: 3,
                },
              }}
            >
              <NotificationsIcon sx={{ color: 'white' }} />
            </Badge>
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              '& .MuiPopover-paper': {
                width: '900px',
                maxHeight: '600px',
                bgcolor: '#f0f0f0',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
                p: 2,
              }}
            >

              {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                  <CircularProgress size={24} />
                </Box>
              ) : error ? (
                <Typography color="error" sx={{ p: 2 }}>
                  {error}
                </Typography>
              ) : notifications.length === 0 ? (
                <Typography sx={{ p: 2, textAlign: 'center', color: '#666' }}>
                  No new notifications
                </Typography>
              ) : (
                notifications.map((notification) => (
                  <MatchRow
                    key={notification.formula_id}
                    match={notification}
                    handleRowClick={handleRowClick}
                  />
                ))
              )}
            </Box>
          </Popover>
        </Toolbar>
      </AppBar>

      {/* Notification Permission Dialog */}
      <Dialog open={showPermissionDialog} onClose={() => setShowPermissionDialog(false)}>
        <DialogTitle>Enable Notifications</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Would you like to receive desktop notifications for new matches?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => requestNotificationPermission()} color="primary">
            Allow
          </Button>
          <Button onClick={() => setShowPermissionDialog(false)} color="primary">
            Deny
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};


export default Header;