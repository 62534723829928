import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontSize: 16, 
    h6: { fontSize: "1.5rem" }, // Adjust headers or any specific variant as needed
  },
});

export default theme;


