import React ,{useState} from 'react'
import loginUser from '../../Apis/SignInApi';
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import './SignIn.css'
function SignIn() {

	const [email, setEmail]= useState('');
	const [password, setPassword]= useState('');
	const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLogErr, setisLogErr] = useState(false);
	const [validated, setValidated] = useState(false);

	const navigate = useNavigate();

	const handleSubmit = async(event) =>{
		const form = event.currentTarget;
		let res
		if (form.checkValidity() === false) {
		  event.preventDefault();
		  event.stopPropagation();
		}
		else{
			const loginData = {email: email, password:password}
			res = await loginUser.loginUser(loginData);
			if (res && res.data) {
				setValidated(true);
				res = res.data;
				setIsSubmitted(true);
				localStorage.setItem('access_token',res.access_token);
				setTimeout(() => {
					navigate("/home");
				}, 500);
			}
			else{
				setValidated(false);
				setisLogErr(true)
	
				setTimeout(() => {
					setisLogErr(false);
				}, 3000);
			}
        

		}
	};
	return (
		<div>
			<div className='signInForm'>
				<div className="row" style={{margin: '0px auto'}}>
					<div className="col-md-12" style={{width: '130%'}}>
						<Form className="fh5co-form animate-box" data-animate-effect="fadeIn"
								noValidate validated={validated} >
							<h2>Sign In</h2>
							{isSubmitted ? <div className="alert alert-success" role="alert">Login successfull</div> :''}
                        	{isLogErr ? 'Invalid credentials , Please Check Your credentials !!':''}
							<Form.Group className="form-group">
								<label htmlFor="email" className="sr-only">Username</label>
								<Form.Control type="text" className="form-control" controlid="Form.Username"
								onChange={e => setEmail(e.target.value)}  placeholder="Username" autoComplete="off" />
							</Form.Group>
							<Form.Group className="form-group">
								<label htmlFor="password" className="sr-only">Password</label>
								<Form.Control type="password" className="form-control" controlid="Form.password"
								onChange={e => setPassword(e.target.value)} placeholder="Password" autoComplete="off" />
							</Form.Group>
							
							{/* <div className="form-group">
								<p>Not registered? <NavLink to="/signup">Sign Up</NavLink> | <NavLink to="/forgetPassword">Forget Password</NavLink></p>
								<p>Got a Temporary Password ? <NavLink to="/resetPassword">Reset Password</NavLink></p>
							</div> */}
							<div className="form-group">
								<input type="button" value="Sign In" onClick={handleSubmit} className="btn btn-primary" />
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SignIn